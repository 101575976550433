import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../button';
import ClaimPrizeModal from './modal';

export default function ClaimPrize() {
  const { t } = useTranslation('home');
  const [showClaimPrize, setShowClaimPrize] = useState(false);

  return (
    <>
      <Button gradient onClick={() => setShowClaimPrize(true)}>
        {t('header.claim')}
      </Button>
      {showClaimPrize && (
        <ClaimPrizeModal onClose={() => setShowClaimPrize(false)} />
      )}
    </>
  );
}
