import clsx from 'clsx';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../context/auth-context';
import { loginRoute } from '../../utils/routes';
import AdminButton from '../admin-button';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: any;
}

export default function AdminDashboard(props: Props) {
  const { t } = useTranslation('common');
  const { apiKey, deleteApiKey } = useContext(AuthContext);
  const navigate = useNavigate();

  function onLogout() {
    deleteApiKey();
    navigate(loginRoute);
  }

  return (
    <div
      {...{
        ...props,
        className: clsx('font-Inter text-black', props.className),
      }}
    >
      <div className="flex items-center p-8 bg-white">
        <div className="text-[1.75rem] font-semibold mr-auto">
          {t('adminDashboard.title')}
        </div>
        {apiKey && (
          <AdminButton size="small" onClick={onLogout}>
            {t('adminDashboard.logout')}
          </AdminButton>
        )}
      </div>
      <div className="bg-[#F7F7F8] px-8 py-6 min-h-screen">
        {props.children}
      </div>
    </div>
  );
}
