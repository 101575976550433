import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loadRaffleContract } from '../../../contracts/raffle';
import { RaffleItem } from '../../../interfaces/raffle';
import { getPublicRpc } from '../../../utils/rpc-rotater';
import { batchPromiseHandler } from '../../../utils/utils';
import Section from '../../section';
import SubTitle from '../../subtitle';
import Title from '../../title';
import RaffleItemEl from './item';
import clsx from 'clsx';

// const testData: Raffle[] = [
//   {
//     id: '1',
//     state: RaffleState.ACTIVE,
//     title: 'Weekly Raffle',
//     type: 'weekly',
//     drawingAt: Date.now() + 1000 * 60 * 60 * 24 * 7,
//     prize: '10000000',
//     prizeShares: [40, 60],
//     prizeType: RafflePrizeType.RAFFLE,
//     participants: 5,
//   },
//   {
//     id: '2',
//     state: RaffleState.DRAWING,
//     title: 'Montly Raffle',
//     type: 'monthly',
//     drawingAt: Date.now() + 1000 * 60 * 60 * 24 * 7 * 4,
//     prize: '10000000',
//     prizeShares: [40, 60],
//     prizeType: RafflePrizeType.RAFFLE,
//     participants: 5,
//   },
//   {
//     id: '3',
//     state: RaffleState.COMPLETED,
//     title: 'Grand Prize',
//     type: 'grand',
//     drawingAt: Date.now() + 1000 * 60 * 60 * 24 * 7 * 4 * 12,
//     prize: '10000000',
//     prizeShares: [40, 60],
//     prizeType: RafflePrizeType.ETHER,
//     participants: 5,
//   },
//   {
//     id: '4',
//     state: RaffleState.COMPLETED,
//     title: 'Surprise Raffle',
//     type: 'surprise',
//     prize: '10000000',
//     drawingAt: Date.now() + 1000 * 60 * 60 * 24 * 4,
//     prizeShares: [40, 60],
//     prizeType: RafflePrizeType.ETHER,
//     participants: 5,
//   },
// ];

// const sortOrder: RaffleType[] = ['weekly', 'monthly', 'grand', 'surprise'];

export const loadRaffles = async () => {
  const contract = loadRaffleContract(getPublicRpc());

  const rafflesLength = await contract.getRafflesLength();

  const raffles = await batchPromiseHandler(
    +process.env.REACT_APP_RAFFLE_BATCH_SIZE!,
    Array.from(Array(Number(rafflesLength)).keys())
      .filter((n) => {
        return !process.env
          .REACT_APP_IGNORE_RAFFLES!.split(',')
          .includes(n.toString());
      })
      .map(
        (n) => async () =>
          loadRaffleContract(getPublicRpc()).getRaffle(BigInt(n))
      )
  );

  return raffles.filter((r) => r.prizeLeft > 0) as RaffleItem[];
};

export default function CountdownSection() {
  const { t } = useTranslation('home');
  const [raffles, setRaffles] = useState<RaffleItem[]>();

  useEffect(() => {
    load();
  }, []);

  async function load() {
    const raffles = await loadRaffles();

    console.log('raffles fetched', raffles);

    raffles.sort(
      (a: RaffleItem, b: RaffleItem) =>
        a.drawingAt.getTime() - b.drawingAt.getTime()
    );
    // raffles.sort((a: RaffleItem, b: RaffleItem) => {
    //   const aOrder = sortOrder.indexOf(a.raffleType);
    //   const bOrder = sortOrder.indexOf(b.raffleType);

    //   return aOrder - bOrder;
    // });

    setRaffles(raffles);
  }

  return (
    <>
      <Section id="raffles">
        <Title>{t('raffles.title')}</Title>
        <SubTitle className="mt-[-25px] mb-12 max-md:leading-none max-md:mt-2">
          {t('raffles.subtitle')}
        </SubTitle>

        {raffles && (
          <div
            className={clsx(
              raffles.length > 1 ? 'grid-cols-2' : 'grid-cols-1',
              'grid gap-y-20 mt-5 max-lg:grid-cols-1'
            )}
          >
            {raffles.map((item) => (
              <RaffleItemEl key={item.id} raffle={item} />
            ))}
          </div>
        )}
      </Section>
      <div className="separator"></div>
    </>
  );
}
