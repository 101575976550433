import clsx from 'clsx';
import styles from './index.module.css';

export default function AboutSectionBox() {
  return (
    <div className="relative h-full">
      <img alt="pot" className={styles.glow} src="/images/box-glow.svg" />
      <img alt="pot" className={styles.back} src="/images/box-back.png" />
      <img alt="pot" className={styles.middle} src="/images/box-middle.png" />
      <img
        alt="pot"
        className={clsx(styles.raffle, styles['raffle-1'])}
        src="/images/box-raffle-right.png"
      />

      <img
        alt="pot"
        className={clsx(styles.coin, styles['coin-1'])}
        src="/images/raffle-coin.png"
      />
      <img
        alt="pot"
        className={clsx(styles.coin, styles['coin-2'])}
        src="/images/ethereum-coin.png"
      />
      <img
        alt="pot"
        className={clsx(styles.coin, styles['coin-3'])}
        src="/images/ethereum-coin.png"
      />
      <img
        alt="pot"
        className={clsx(styles.coin, styles['coin-4'])}
        src="/images/raffle-coin.png"
      />
      <img
        alt="pot"
        className={clsx(styles.raffle, styles['raffle-2'])}
        src="/images/box-raffle-left.png"
      />

      <img
        alt="pot"
        className={clsx(styles.coin, styles['coin-5'])}
        src="/images/raffle-coin.png"
      />
      <img
        alt="pot"
        className={clsx(styles.coin, styles['coin-6'])}
        src="/images/raffle-coin.png"
      />

      <img
        alt="pot"
        className={clsx(styles.raffle, styles['raffle-3'])}
        src="/images/box-raffle-right.png"
      />
      <img
        alt="pot"
        className={clsx(styles.raffle, styles['raffle-4'])}
        src="/images/box-raffle-left.png"
      />

      <img
        alt="pot"
        className={clsx(styles.coin, styles['coin-7'])}
        src="/images/ethereum-coin.png"
      />
      <img
        alt="pot"
        className={clsx(styles.coin, styles['coin-8'])}
        src="/images/ethereum-coin.png"
      />
      <img
        alt="pot"
        className={clsx(styles.coin, styles['coin-9'])}
        src="/images/ethereum-coin.png"
      />
      <img
        alt="pot"
        className={clsx(styles.coin, styles['coin-10'])}
        src="/images/raffle-coin.png"
      />
      <img
        alt="pot"
        className={clsx(styles.coin, styles['coin-11'])}
        src="/images/raffle-coin.png"
      />

      <img alt="pot" className={styles.front} src="/images/box-front.png" />
    </div>
  );
}
