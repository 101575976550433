import clsx from 'clsx';
import React from 'react';
import styles from './index.module.css';

export interface FormSelectProps
  extends React.HTMLAttributes<HTMLSelectElement> {
  disabled?: boolean;
  error?: boolean;
}

const FormSelect = React.forwardRef((props: FormSelectProps, ref?: any) => {
  let className = clsx(
    `border outline-none rounded-md text-[#121212] p-2.5 px-3 cursor-pointer relative appearance-none`,
    props.error ? 'border-red-500 bg-red-100' : 'border-[#6F6F6F]',
    // props.disabled ? 'opacity-50 pointer-events-none' : undefined,
    styles.select,
    props.className
  );

  return (
    <select {...{ ...props, error: undefined }} className={className} ref={ref}>
      {props.children}
    </select>
  );
});

export default FormSelect;
