import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getUniswapUrl } from '../../../utils/utils';
import Button from '../../button';
import Logo from '../../logo';
import Navbar from '../../navbar';
import Section from '../../section';
import SubTitle from '../../subtitle';
import styles from './index.module.css';
import HomeSectionPot from './pot';
import PrizePool from './prize-pool';

export default function HomeSection() {
  const { t } = useTranslation('home');

  return (
    <div className={styles.background}>
      <Navbar />
      <Section id="home">
        <div className="flex">
          <div className="flex-1 max-w-[50%] max-lg:max-w-full">
            <div className="flex justify-center ml-[-50px] max-md:ml-0">
              <Logo size="large" />
            </div>
            <SubTitle className="mt-[-20px]">{t('header.subtitle')}</SubTitle>
            <PrizePool />

            {/* <p className="font-Dimbo text-[5rem] text-center text-white mb-6 mt-5 max-md:text-[4rem]">
              Coming soon...
            </p>
            <p className="font-Dimbo text-[2rem] max-md:text-[1.8rem] text-center text-white">
              In the meanwhile, check us out on:
            </p> */}
            {/* <p className="font-Broadway3DFilled text-[2.5rem] text-stroke text-center mt-[-15px]">
              Be in it to win it
            </p>
            <p className="font-Broadway3DFilled text-[4rem] text-stroke text-center text-gradient">
              CURRENT PRIZE POOL
            </p>
            <div className="mt-[-20px] bg-white px-6 py-10 text-font-black flex space-x-8 rounded-[26px]">
              <span className="mr-2 mt-2 font-Broadway3DFilled text-[96px] leading-[60px]">
                R
              </span>
              <span className="text-[90px] leading-[60px]">1,402,240.95</span>
            </div> */}
            <div className="flex space-x-4 mt-5 max-md:flex-col max-md:space-x-0 max-md:space-y-4">
              <Link className="flex-1" to={getUniswapUrl} target="_blank">
                <Button className="w-full" image="/images/uniswap.png">
                  {t('header.join')}
                </Button>
              </Link>
              <Link className="flex-1" to={process.env.REACT_APP_CHART_URL!}>
                <Button className="w-full" image="/images/chart.png">
                  {t('header.chart')}
                </Button>
              </Link>
            </div>
          </div>
          <div className="flex-1 relative max-lg:hidden">
            <HomeSectionPot />
            {/* <Button className={styles.claim} gradient>
              Claim your price here!
            </Button> */}
          </div>
        </div>
      </Section>
      <div className={clsx(styles.separator, 'mb-4')} />
      {/* <div className={clsx(styles.separator, styles.right)} /> */}
    </div>
  );
}
// export default function HomeSection() {
//   const { t } = useTranslation('common');

//   return (
//     <>
//       <Section>
//         <div className="flex">
//           <div className="flex-1">
//             <div className="flex justify-center ml-[-50px]">
//               {/* <Logo size="large" /> */}
//               <img
//                 src="/images/logo.png"
//                 alt="logo"
//                 className="w-[400px] justify-self-center"
//               />
//             </div>
//             <p className="font-Broadway3DFilled text-[2.5rem] text-stroke text-center mt-[-15px]">
//               Be in it to win it
//             </p>
//             <p className="font-Broadway3DFilled text-[4rem] text-stroke text-center text-gradient">
//               CURRENT PRIZE POOL
//             </p>
//             <div className="mt-[-20px] bg-white px-6 py-10 text-font-black flex space-x-8 rounded-[26px]">
//               <span className="mr-2 mt-2 font-Broadway3DFilled text-[96px] leading-[60px]">
//                 R
//               </span>
//               <span className="text-[90px] leading-[60px]">1,402,240.95</span>
//             </div>
//             <div className="flex space-x-4 mt-8">
//               <Button className="flex-1" image="/images/uniswap.png">
//                 Join the RAFFLE
//               </Button>
//               <Button className="flex-1" image="/images/chart.png">
//                 See chart
//               </Button>
//             </div>
//           </div>
//           <div className="flex-1 relative">
//             <HomeSectionPot />
//             <Button className={styles.claim} gradient>
//               Claim your price here!
//             </Button>
//           </div>
//         </div>
//       </Section>
//       <div className={styles.separator} />
//     </>
//   );
// }
