import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import FormGroup from '../../../shared/form/group';
import FormInput from '../../../shared/form/input';
import FormLabel from '../../../shared/form/label';
import FormSelect from '../../../shared/form/select';

function RaffleFormPrizeShares(props: { disabled?: boolean }) {
  const { t } = useTranslation('admin');

  const {
    register,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useFormContext();

  const { fields } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'prizeShares', // unique name for your Field Array
  });

  useEffect(() => {
    setValue('prizeShares', [100]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangeSelect(ev: any) {
    const currentValue = getValues('prizeShares');
    const newValue: number[] = [];

    for (let i = 0; i < +ev.target.value; i++) {
      if (i <= currentValue.length - 1) {
        newValue.push(currentValue[i]);
      } else {
        newValue.push(100);
      }
    }

    setValue('prizeShares', newValue);
  }

  return (
    <FormGroup className="flex-shrink-0">
      <FormLabel>{t('form.prizeShares')}</FormLabel>
      <div className="flex space-x-3">
        <FormSelect
          className="w-[80px]"
          onChange={onChangeSelect}
          disabled={props.disabled}
        >
          {Array.from(Array(10).keys()).map((i) => (
            <option value={i + 1}>{i + 1}</option>
          ))}
        </FormSelect>
        {fields.map((field, i) => (
          <FormInput
            {...register('prizeShares.' + i)}
            className="w-[80px]"
            disabled={props.disabled}
          />
        ))}
        {/* <div className="flex flex-col space-y-3">
          {fields.map((field, i) => (
            <FormInput {...register('prizeShares.' + i)} className="w-[80px]" />
          ))}
        </div> */}
      </div>
      {errors.prizeShares && (
        <div className="text-danger text-sm">
          {t('form.errors.prizeShares')}
        </div>
      )}
    </FormGroup>
  );
}

export default RaffleFormPrizeShares;
