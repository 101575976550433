import { Toaster } from 'react-hot-toast';
import { Route, Routes } from 'react-router-dom';
import { AuthContextProvider } from './context/auth-context';
import AdminPage from './pages/admin';
import AdminDeployPage from './pages/admin/deploy';
import HomePage from './pages/home';
import LoginPage from './pages/login';
import {
  adminDeployRoute,
  adminRoute,
  homeRoute,
  loginRoute,
} from './utils/routes';

function App() {
  return (
    <AuthContextProvider>
      {/* <Navbar /> */}
      <main>
        <Routes>
          <Route path={homeRoute} element={<HomePage />} />
          <Route path={loginRoute} element={<LoginPage />} />
          <Route path={adminRoute} element={<AdminPage />} />
          <Route path={adminDeployRoute} element={<AdminDeployPage />} />
        </Routes>
      </main>

      <Toaster />
    </AuthContextProvider>
  );
}

export default App;
