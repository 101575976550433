import '@leenguyen/react-flip-clock-countdown/dist/index.css';
import i18next from 'i18next';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { Web3ContextProvider } from './context/web3-context';
import './index.css';
import reportWebVitals from './reportWebVitals';
import i18nSettings from './translations/i18n-settings';

i18next.init(i18nSettings);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
  <I18nextProvider i18n={i18next}>
    <Web3ContextProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Web3ContextProvider>
  </I18nextProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
