import HTMLReactParser from 'html-react-parser';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AdminDashboard from '../../../components/admin';
import DashboardBox from '../../../components/admin/box';
import Web3Balance from '../../../components/shared/balance';
import { loadRaffleTokenContract } from '../../../contracts/raffle-token';
import AuthGuard from '../../../guards/auth';
import { getPublicRpc } from '../../../utils/rpc-rotater';

import AdminDeployTradingStatus from '../../../components/admin/trading-status';
import {
  getChainScannerUrl,
  getUniswapLiquidityUrl,
} from '../../../utils/utils';

export interface DeployData {
  ownerBalance: bigint;
  trading: boolean;
  reflectionPool: bigint;
}

function AdminDeployPage() {
  const { t } = useTranslation('admin-deploy');
  const [data, setData] = useState<DeployData>();

  useEffect(() => {
    refresh();
  }, []);

  async function refresh() {
    setData({
      ownerBalance: await loadRaffleTokenContract(getPublicRpc()).balanceOf(
        process.env.REACT_APP_ADMIN_WALLET_ADDRESS!
      ),
      trading:
        (await loadRaffleTokenContract(getPublicRpc()).getStartedOn()) > 0,
      reflectionPool: await loadRaffleTokenContract(
        getPublicRpc()
      ).getReflectionPool(),
    });
    // setData({
    //   ownerBalance: BigInt(0),
    //   trading: false,
    //   reflectionPool: BigInt(0),
    // });
  }

  return (
    <AuthGuard>
      <AdminDashboard>
        <div className="flex justify-center">
          <div className="w-full max-w-[1024px]">
            <DashboardBox className="space-y-2">
              <div>
                {HTMLReactParser(
                  t('raffleToken', {
                    url: getChainScannerUrl(
                      process.env.REACT_APP_RAFFLE_TOKEN_ADDRESS!
                    ),
                    address: process.env.REACT_APP_RAFFLE_TOKEN_ADDRESS,
                  })
                )}
              </div>
              <div>
                {HTMLReactParser(
                  t('raffle', {
                    url: getChainScannerUrl(
                      process.env.REACT_APP_RAFFLE_ADDRESS!
                    ),
                    address: process.env.REACT_APP_RAFFLE_ADDRESS,
                  })
                )}
              </div>
              <div>
                {HTMLReactParser(
                  t('pair', {
                    address: process.env.REACT_APP_RAFFLE_ADDRESS,
                    url: getUniswapLiquidityUrl,
                  })
                )}
              </div>

              {data && (
                <>
                  <div>
                    <span className="mr-1">{t('ownerBalance')}</span>
                    <span className="font-bold">
                      <Web3Balance
                        balance={data.ownerBalance}
                        currency="raffle"
                      />
                    </span>
                  </div>
                  <div>
                    <div>
                      <span className="mr-1">{t('reflectionPool')}</span>
                      <span className="font-bold">
                        <Web3Balance
                          balance={data.reflectionPool}
                          currency="raffle"
                        />
                      </span>
                    </div>
                  </div>
                  {/* <AdminDeployReflectionPool data={data} onSet={refresh} /> */}
                  {/* <div>
                    <span className="mr-1">{t('reflectionPool')}</span>
                    <span className="font-bold">
                      <Web3Balance
                        balance={data.reflectionPool}
                        currency="raffle"
                      />
                    </span>
                  </div> */}
                  <AdminDeployTradingStatus data={data} onChange={refresh} />
                </>
              )}
            </DashboardBox>
          </div>
        </div>
      </AdminDashboard>
    </AuthGuard>
  );
}

export default AdminDeployPage;
