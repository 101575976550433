import dateFormat from 'dateformat';
import { ethers } from 'ethers';
import HTMLReactParser from 'html-react-parser';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setRaffleWinners } from '../../../../api/raffles';
import AdminContext from '../../../../context/admin-context';
import Web3Context, { metamaskErrors } from '../../../../context/web3-context';
import { loadRaffleContract } from '../../../../contracts/raffle';
import { Raffle, RaffleWinner } from '../../../../interfaces/raffle';
import { getPublicRpc } from '../../../../utils/rpc-rotater';
import {
  batchPromiseHandler,
  getChainScannerUrl,
} from '../../../../utils/utils';
import AdminButton from '../../../admin-button';
import Modal from '../../../modal';
import Notification from '../../../shared/notification';
import RaffleWinnerItem from './item';

export default function RaffleWinnersModal(props: {
  raffle: Raffle;
  onClose: () => void;
}) {
  const { t } = useTranslation('admin');
  const { setRaffles, raffles } = useContext(AdminContext);
  const [loading, setLoading] = useState(false);
  const { provider, connect } = useContext(Web3Context);
  const [transactionMessage, setTransactionMessage] = useState<
    { color: 'success' | 'danger' | 'neutral'; message: string } | undefined
  >();
  const { refresh } = useContext(AdminContext);

  function onClose() {
    if (!loading) {
      props.onClose();
    }
  }

  useEffect(() => {
    if (!props.raffle.winners) {
      getWinners();
    } else {
      verifyClaimStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.raffle]);

  // Function to process events from a specific block to the latest block
  async function getWinners() {
    const provider = new ethers.JsonRpcProvider(
      process.env.REACT_APP_EVENT_RPC
    );
    const batchSize = BigInt(process.env.REACT_APP_EVENT_LOGS_BATCH_SIZE!);

    const minBlock = BigInt(process.env.REACT_APP_DEPLOY_BLOCK_NUMBER!);
    let maxBlock = BigInt(await provider.getBlockNumber());

    const contract = loadRaffleContract(provider);
    const filter = contract.instance.filters.AppliedForRaffle(props.raffle.cId);

    const raffleWinners: RaffleWinner[] = [];

    let prizeLeft = BigInt(props.raffle.prize);

    // start going down from current block in batches till number of winners matches total prize shares
    while (maxBlock > minBlock) {
      const events = await contract.instance.queryFilter(
        filter,
        maxBlock - batchSize,
        maxBlock
      );

      const result = (
        await batchPromiseHandler(
          +process.env.REACT_APP_RAFFLE_BATCH_SIZE!,
          // eslint-disable-next-line no-loop-func
          events.map((e: any) => async () => {
            const prizeStatus = await loadRaffleContract(
              getPublicRpc()
            ).getRafflePrize(BigInt(props.raffle.cId), e.args[1]);

            if (prizeStatus.prize) {
              prizeLeft -= prizeStatus.prize;
              return {
                address: e.args[1],
                prize: prizeStatus.prize.toString(),
                claimed: prizeStatus.claimed,
              };
            } else {
              return undefined;
            }
          })
        )
      ).filter((r: any) => r !== undefined);

      raffleWinners.push(...result);

      if (prizeLeft <= 0) {
        console.log('no prize left');
        break;
      }

      maxBlock = maxBlock - batchSize - BigInt(1);
    }

    console.log('winners', raffleWinners);

    if (raffleWinners.length > 0) {
      await setRaffleWinners(props.raffle.cId, raffleWinners);
    }

    updateRaffles(raffleWinners);
  }

  async function verifyClaimStatus() {
    const newWinners: RaffleWinner[] = [];
    let changed = false;

    for (const winner of props.raffle.winners!) {
      if (!winner.claimed) {
        console.log('verify claim status of ', winner.address);
        const status = await loadRaffleContract(getPublicRpc()).getRafflePrize(
          BigInt(props.raffle.cId),
          winner.address
        );

        if (status.claimed) {
          changed = true;
        }

        console.log('claim status of ', winner.address, status.claimed);

        newWinners.push({ ...winner, claimed: status.claimed });
      } else {
        newWinners.push(winner);
      }
    }

    if (changed) {
      console.log('update due to change ', newWinners);
      await setRaffleWinners(props.raffle.cId, newWinners);
      updateRaffles(newWinners);
    } else {
      console.log('no changes');
    }
  }

  function updateRaffles(winners: RaffleWinner[]) {
    setRaffles(
      raffles!.map((raffle) => {
        if (raffle.PK === props.raffle.PK) {
          raffle.winners = winners;
        }

        return raffle;
      })
    );
  }

  async function onReturnPrizeToPool() {
    setLoading(true);
    setTransactionMessage(undefined);
    try {
      await connect(process.env.REACT_APP_ADMIN_WALLET_ADDRESS);
      const signer = await provider!.getSigner();
      const contract = loadRaffleContract(signer!);

      const tx = await contract.returnLeftoverRafflePrizeToPool(
        BigInt(props.raffle.cId)
      );
      setTransactionMessage({
        color: 'neutral',
        message: t('past.winners.returning', {
          url: getChainScannerUrl(tx.hash),
        }),
      });

      const receipt = await tx.wait();
      console.log('transaction was successfull', receipt);
      setTransactionMessage({
        color: 'success',
        message: t('past.winners.returned', {
          url: getChainScannerUrl(tx.hash),
        }),
      });

      await refresh();
    } catch (err: any) {
      console.error('failed to return', err);

      if (!metamaskErrors.includes(err)) {
        setTransactionMessage({
          color: 'danger',
          message: t('past.winners.errors.return'),
        });
      }
    }
    setLoading(false);
  }

  const claimDeadline = new Date(props.raffle.claimDeadline!);
  console.log(
    'test',
    Date.now(),
    claimDeadline.getTime(),
    BigInt(props.raffle.prizeLeft) > 0
  );

  return (
    <Modal className="w-full max-w-[800px]" alt onClose={onClose}>
      {transactionMessage && (
        <Notification color={transactionMessage.color} disableScroll>
          {HTMLReactParser(transactionMessage.message)}
        </Notification>
      )}
      {!props.raffle.winners && <div>{t('past.winners.loading')}</div>}
      {props.raffle.winners && (
        <div>
          <table className="border-collapse w-full text-left">
            <thead className="border-b">
              <tr>
                <td>{t('past.winners.winner')}</td>
                <td>{t('past.winners.prize')}</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {props.raffle.winners.map((item, i) => (
                <RaffleWinnerItem
                  key={item.address}
                  item={item}
                  raffle={props.raffle}
                  index={i}
                />
              ))}
            </tbody>
          </table>
          <div className="mt-1 pt-1 font-medium text-sm border-t">
            {t('past.winners.claimDeadline', {
              deadline: dateFormat(claimDeadline, 'dd.mm.yyyy - hh:MM'),
            })}
          </div>
          {Date.now() > claimDeadline.getTime() &&
            BigInt(props.raffle.prizeLeft) > 0 &&
            (!transactionMessage || transactionMessage.color !== 'success') && (
              <div className="flex justify-end">
                <AdminButton
                  className="w-[200px]"
                  disabled={loading}
                  onClick={onReturnPrizeToPool}
                >
                  {t('past.winners.return')}
                </AdminButton>
              </div>
            )}
        </div>
      )}
    </Modal>
  );
}
