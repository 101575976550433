import clsx from 'clsx';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}
// 1032px
export default function Section(props: Props) {
  return (
    <div
      {...props}
      className={clsx(
        props.className,
        'py-20 flex justify-center max-lg:px-10 max-md:px-5'
      )}
    >
      <div className="w-full max-w-[1032px]">{props.children}</div>
    </div>
  );
}
