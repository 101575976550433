import {
  ContractTransactionResponse,
  JsonRpcProvider,
  JsonRpcSigner,
  ethers,
} from 'ethers';
import {
  RaffleFromContract,
  RaffleMinParticipationToken,
  RafflePrizeStatus,
  RafflePrizeType,
} from '../interfaces/raffle';

const { abi } = require('./abi/Raffle.json');

export class RaffleContract {
  instance: any;

  constructor(instance: any) {
    this.instance = instance;
  }

  public async startRaffle(
    name: string,
    raffleType: string,
    prizeShares: number[],
    drawingAt: Date,
    prize: bigint,
    prizeType: RafflePrizeType,
    minParticipationAmount: bigint,
    maxParticipationAmount: bigint,
    minParticipationTokenContract: string,
    minParticipationTokenBalance: bigint
  ): Promise<ContractTransactionResponse> {
    console.log('start', {
      name,
      raffleType,
      prizeShares,
      drawingAt,
      prize,
      prizeType,
      minParticipationAmount,
      maxParticipationAmount,
      minParticipationTokenContract,
      minParticipationTokenBalance,
    });
    return this.instance.startRaffle(
      name,
      raffleType,
      prizeShares,
      Math.floor(drawingAt.getTime() / 1000),
      prize,
      prizeType,
      minParticipationAmount,
      maxParticipationAmount,
      minParticipationTokenContract,
      minParticipationTokenBalance
    );
  }

  public async getRafflesLength(): Promise<bigint> {
    return this.instance.rafflesLength();
  }

  public async applyForRaffle(
    id: bigint
  ): Promise<ContractTransactionResponse> {
    return this.instance.applyForRaffle(id);
  }

  // public async getRaffles(): Promise<void> {
  //   const rafflesLength = await this.getRafflesLength();

  //   await batchPromiseHandler(+process.env.REACT_APP_RAFFLE_BATCH_SIZE!, Array.from(Array(rafflesLength)).map((n) => () => ))

  //   const raffles = await Promise.all(
  //     Array.from(Array(rafflesLength).keys()).filter(
  //       (n) => n >= Number(rafflesLength) - +process.env.REACT_APP_MAX_RAFFLES!
  //     )
  //   );

  //   console.log('fetch', raffles);

  //   // return raffles;
  // }

  public async getRaffle(id: bigint): Promise<RaffleFromContract> {
    const [
      name,
      raffleType,
      state,
      drawingAt,
      prize,
      prizeType,
      prizeLeft,
      participationNum,
      participants,
      minParticipationAmount,
      claimDeadline,
      exists,
    ] = await this.instance.raffles(id);

    return {
      id,
      name,
      raffleType,
      state: Number(state),
      drawingAt: new Date(Number(drawingAt) * 1000),
      prize,
      prizeType: Number(prizeType),
      prizeLeft,
      participationNum,
      participants,
      minParticipationAmount,
      claimDeadline:
        claimDeadline > 0 ? new Date(Number(claimDeadline) * 1000) : undefined,
      exists,
    };
  }

  public async getNumberRange(id: bigint, address: string): Promise<bigint[]> {
    const iterable = await this.instance.getRaffleNumberRange(id, address);
    const result: bigint[] = [];
    const keys = Object.keys(iterable).sort(
      (a, b) => parseInt(a) - parseInt(b)
    );

    for (const key of keys) {
      result.push(iterable[key]);
    }

    return result;
  }

  public async bumpRaffleDrawing(
    id: bigint
  ): Promise<ContractTransactionResponse> {
    return this.instance.bumpRaffleDrawing(id);
  }

  public async returnLeftoverRafflePrizeToPool(
    id: bigint
  ): Promise<ContractTransactionResponse> {
    return this.instance.returnLeftoverRafflePrizeToPool(id);
  }

  public async getRafflePrize(
    id: bigint,
    participant: string
  ): Promise<RafflePrizeStatus> {
    const [prize, claimed] = await this.instance.getRafflePrize(
      id,
      participant
    );

    return { prize, claimed };
  }

  public async raffleMinParticipationTokens(
    id: bigint
  ): Promise<RaffleMinParticipationToken> {
    const [tokenContract, balance] =
      await this.instance.raffleMinParticipationTokens(id);

    return { tokenContract, balance };
  }

  public async claimRafflePrize(
    id: bigint
  ): Promise<ContractTransactionResponse> {
    return this.instance.claimRafflePrize(id);
  }

  public async reservedEther(): Promise<bigint> {
    return this.instance.reservedEther();
  }
  public async reservedTokens(): Promise<bigint> {
    return this.instance.reservedTokens();
  }
}
// 6.11.1
export const loadRaffleContract = (signer: JsonRpcSigner | JsonRpcProvider) => {
  const instance = new ethers.Contract(
    process.env.REACT_APP_RAFFLE_ADDRESS!,
    abi,
    signer
  );

  return new RaffleContract(instance);
};
