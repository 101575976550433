import FlipClockCountdown from '@leenguyen/react-flip-clock-countdown';
import clsx from 'clsx';
import HTMLReactParser from 'html-react-parser';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Web3Context, { metamaskErrors } from '../../../../context/web3-context';
import { loadRaffleContract } from '../../../../contracts/raffle';
import {
  RaffleItem,
  RafflePrizeType,
  RaffleState,
} from '../../../../interfaces/raffle';
import { getPublicRpc } from '../../../../utils/rpc-rotater';
import { getChainScannerUrl } from '../../../../utils/utils';
import Button from '../../../button';
import Web3Balance, { formatBalance } from '../../../shared/balance';
import Notification from '../../../shared/notification';
import Title from '../../../title';
import styles from './index.module.css';
import { loadRaffleTokenContract } from '../../../../contracts/raffle-token';

interface WalletState {
  participating: boolean;
  prize: bigint;
  claimed: boolean;
}

export const getClaimPrizeError = (t: any, err: any) => {
  if (err.message && err.message.includes('execution reverted: "DE"')) {
    return t('raffles.errors.claimExpired');
  } else if (err.message && err.message.includes('execution reverted: "AL"')) {
    return t('raffles.errors.alreadyClaimed');
  } else if (err.message && err.message.includes('execution reverted: "NP"')) {
    return t('raffles.errors.claimExpired');
  } else if (!metamaskErrors.includes(err)) {
    return t('raffles.errors.claim');
  }
};

export default function RaffleItemEl(props: { raffle: RaffleItem }) {
  const [loading, setLoading] = useState(false);
  const [transactionMessage, setTransactionMessage] = useState<
    { color: 'success' | 'danger' | 'neutral'; message: string } | undefined
  >();
  const { provider, connect, walletAddress } = useContext(Web3Context);
  const [walletState, setWalletState] = useState<WalletState>();

  const { t } = useTranslation('home');

  const countdown = (
    <FlipClockCountdown
      to={props.raffle.drawingAt}
      digitBlockStyle={{
        width: 45,
        height: 106,
        fontSize: 64,
        // overflow: 'hidden',
        backgroundColor: '#131313',
      }}
      hideOnComplete={false}
      // separatorStyle={{marginRight: 10}}
      dividerStyle={{ color: '#4B01A8', height: 1 }}
      labelStyle={{ fontSize: 20 }}
      labels={[
        t('raffles.days'),
        t('raffles.hours'),
        t('raffles.minutes'),
        t('raffles.seconds'),
      ]}
    />
  );

  useEffect(() => {
    if (walletAddress) {
      fetchWalletAddressStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletAddress]);

  async function onConnect() {
    await connect();
  }

  async function fetchWalletAddressStatus() {
    const range = await loadRaffleContract(getPublicRpc()).getNumberRange(
      props.raffle.id,
      walletAddress!
    );

    const result: WalletState = {
      participating: range[0] > 0 || range[1] > 0,
      prize: BigInt(0),
      claimed: false,
    };

    // fetch prize and claim status if participated and raffle ended
    if (props.raffle.state === RaffleState.COMPLETED && result.participating) {
      const { prize, claimed } = await loadRaffleContract(
        getPublicRpc()
      ).getRafflePrize(props.raffle.id, walletAddress!);

      result.prize = prize;
      result.claimed = claimed;
    }

    console.log('result fetched', props.raffle.id, result);

    setWalletState(result);
  }

  async function onParticipate() {
    setLoading(true);
    setTransactionMessage(undefined);
    try {
      await connect();
      const signer = await provider!.getSigner();
      const contract = loadRaffleContract(signer!);

      const tx = await contract.applyForRaffle(props.raffle.id);
      setTransactionMessage({
        color: 'neutral',
        message: t('raffles.applying', {
          url: getChainScannerUrl(tx.hash),
        }),
      });

      const receipt = await tx.wait();
      console.log('transaction was successfull', receipt);
      setWalletState({ ...walletState!, participating: true });
      setTransactionMessage(undefined);
    } catch (err: any) {
      console.error('failed to participate', err);
      if (err.message && err.message.includes('execution reverted: "AL"')) {
        setTransactionMessage({
          color: 'danger',
          message: t('raffles.errors.alreadyApplied'),
        });
      } else if (
        err.message &&
        err.message.includes('execution reverted: "MP"')
      ) {
        setTransactionMessage({
          color: 'danger',
          message: t('raffles.errors.minParticipationAmount', {
            amount: formatBalance(props.raffle.minParticipationAmount),
          }),
        });
      } else if (
        err.message &&
        err.message.includes('execution reverted: "DE"')
      ) {
        setTransactionMessage({
          color: 'danger',
          message: t('raffles.errors.deadline'),
        });
      } else if (
        err.message &&
        err.message.includes('execution reverted: "EX"')
      ) {
        setTransactionMessage({
          color: 'danger',
          message: t('raffles.errors.excluded'),
        });
      } else if (
        err.message &&
        err.message.includes('execution reverted: "TB"')
      ) {
        const minParticipationToken = await loadRaffleContract(
          getPublicRpc()
        ).raffleMinParticipationTokens(props.raffle.id);

        const name = await loadRaffleTokenContract(
          getPublicRpc(),
          minParticipationToken.tokenContract
        ).name();

        let decimals = 0;

        try {
          decimals = Number(
            await loadRaffleTokenContract(
              getPublicRpc(),
              minParticipationToken.tokenContract
            ).decimals()
          );
        } catch (err) {
          console.log('not erc20 contract', err);
        }

        setTransactionMessage({
          color: 'danger',
          message: t('raffles.errors.minOtherTokenParticipationAmount', {
            url: getChainScannerUrl(minParticipationToken.tokenContract),
            name,
            amount:
              minParticipationToken.balance /
              (decimals > 0 ? BigInt(Math.pow(10, decimals)) : BigInt(1)),
          }),
        });
      } else if (!metamaskErrors.includes(err)) {
        setTransactionMessage({
          color: 'danger',
          message: t('raffles.errors.participate'),
        });
      }
    }
    setLoading(false);
  }

  async function onClaimPrize() {
    setLoading(true);
    setTransactionMessage(undefined);
    try {
      await connect();
      const signer = await provider!.getSigner();
      const contract = loadRaffleContract(signer!);

      const tx = await contract.claimRafflePrize(props.raffle.id);
      setTransactionMessage({
        color: 'neutral',
        message: t('raffles.claiming', {
          url: getChainScannerUrl(tx.hash),
        }),
      });

      const receipt = await tx.wait();
      console.log('transaction was successfull', receipt);
      setWalletState({ ...walletState!, claimed: true });
      setTransactionMessage(undefined);
    } catch (err: any) {
      console.error('failed to claim', err);
      const error = getClaimPrizeError(t, err);

      if (error) {
        setTransactionMessage({
          color: 'danger',
          message: error,
        });
      }

      // if (err.message && err.message.includes('execution reverted: "DE"')) {
      //   setTransactionMessage({
      //     color: 'danger',
      //     message: t('raffles.errors.claimExpired'),
      //   });
      // } else if (
      //   err.message &&
      //   err.message.includes('execution reverted: "AL"')
      // ) {
      //   setTransactionMessage({
      //     color: 'danger',
      //     message: t('raffles.errors.alreadyClaimed'),
      //   });
      // } else if (
      //   err.message &&
      //   err.message.includes('execution reverted: "NP"')
      // ) {
      //   setTransactionMessage({
      //     color: 'danger',
      //     message: t('raffles.errors.notWon'),
      //   });
      // } else if (!metamaskErrors.includes(err)) {
      //   setTransactionMessage({
      //     color: 'danger',
      //     message: t('raffles.errors.claim'),
      //   });
      // }
    }
    setLoading(false);
  }

  const currentDate = Date.now();
  const showParticipate =
    props.raffle.state === RaffleState.ACTIVE &&
    currentDate < new Date(props.raffle.drawingAt).getTime();

  const drawing =
    (props.raffle.state === RaffleState.ACTIVE &&
      currentDate >= new Date(props.raffle.drawingAt).getTime()) ||
    props.raffle.state === RaffleState.DRAWING;

  return (
    <div className="flex flex-col items-center">
      {walletState &&
        walletState.participating &&
        props.raffle.state !== RaffleState.COMPLETED && (
          <Notification color="success" disableScroll>
            {t('raffles.participating')}
          </Notification>
        )}
      {walletState && walletState.prize > 0 && walletState.claimed && (
        <Notification color="success" disableScroll>
          {t('raffles.claimed')}
        </Notification>
      )}
      {transactionMessage && (
        <Notification color={transactionMessage.color} disableScroll>
          {HTMLReactParser(transactionMessage.message)}
        </Notification>
      )}
      {props.raffle.raffleType === 'weekly' && (
        <>
          <Title className="!text-[3rem]">{props.raffle.name}</Title>
          <div className={clsx('p-5 mt-[-10px]')}>{countdown}</div>
        </>
      )}
      {props.raffle.raffleType === 'monthly' && (
        <>
          <div className="flex items-end space-x-1 mt-[-25px]">
            <img alt="raffle" src="/images/monthly-l.svg" />
            <Title className="!text-[3rem] leading-none pb-1.5">
              {props.raffle.name}
            </Title>
            <img alt="raffle" src="/images/monthly-r.svg" />
          </div>
          <div className={styles.monthly}>{countdown}</div>
        </>
      )}
      {props.raffle.raffleType === 'grand' && (
        <>
          <div className="flex items-center space-x-3 mt-[-32px] mb-2">
            <img alt="raffle" src="/images/grand.svg" />
            <Title className="leading-none pt-3">{props.raffle.name}</Title>
            <img alt="raffle" src="/images/grand.svg" />
          </div>
          <div className={styles.grand}>{countdown}</div>
        </>
      )}
      {props.raffle.raffleType === 'surprise' && (
        <>
          <Title className="!text-[3rem]">{props.raffle.name}</Title>
          <div className={styles.surprise}>{countdown}</div>
        </>
      )}
      <div className="text-center text-[1.75rem] mt-3 mb-1">
        <span className="pr-2">{t('raffles.win')}</span>
        <span className="text-gradient">
          <Web3Balance
            balance={BigInt(props.raffle.prize)}
            currency={
              props.raffle.prizeType === RafflePrizeType.ETHER
                ? 'ether'
                : 'raffle'
            }
          />
        </span>
      </div>
      {!walletState && !drawing && (
        <Button
          gradient
          className="min-w-[250px]"
          onClick={onConnect}
          disabled={loading}
        >
          {t('raffles.connect')}
        </Button>
      )}

      {walletState && showParticipate && (
        <Button
          gradient
          className="min-w-[250px]"
          onClick={onParticipate}
          disabled={loading || walletState.participating}
        >
          {t('raffles.participate')}
        </Button>
      )}
      {drawing && (
        <Button gradient className="min-w-[250px]" disabled>
          {t('raffles.drawing')}
        </Button>
      )}

      {walletState && props.raffle.state === RaffleState.COMPLETED && (
        <Button
          gradient
          className="min-w-[250px]"
          onClick={onClaimPrize}
          disabled={loading || walletState.prize < 1 || walletState.claimed}
        >
          {t('raffles.claim')}
        </Button>
      )}

      {walletState &&
        props.raffle.state === RaffleState.COMPLETED &&
        !walletState.participating && (
          <p className="mt-2">{t('raffles.notParticipated')}</p>
        )}
      {walletState &&
        props.raffle.state === RaffleState.COMPLETED &&
        walletState.participating &&
        walletState.prize! < 1 && <p className="mt-2">{t('raffles.lost')}</p>}
      {walletState &&
        props.raffle.state === RaffleState.COMPLETED &&
        walletState.participating &&
        walletState.prize! > 0 && (
          <p className="mt-2">
            <span className="pr-1">{t('raffles.won')}</span>
            <Web3Balance
              balance={walletState.prize}
              currency={
                props.raffle.prizeType === RafflePrizeType.ETHER
                  ? 'ether'
                  : 'raffle'
              }
            />
          </p>
        )}
    </div>
  );
}
