import en from './en/en';

const settings = {
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    en,
  },
};

export default settings;
