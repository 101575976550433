import clsx from 'clsx';
import styles from './index.module.css';

interface ModalProps {
  children: React.ReactNode;
  className?: string;
  alt?: boolean;
  noCloseButton?: boolean;
  boxClassName?: string;
  onClose: () => void;
}

function Modal(props: ModalProps) {
  return (
    <>
      <div
        className="fixed top-0 left-0 right-0 bottom-0 bg-black opacity-50 z-20"
        onClick={props.onClose}
      ></div>

      <div
        className={clsx(
          styles.box,
          props.className,
          props.alt ? styles.alt : undefined
        )}
      >
        {!props.noCloseButton && (
          <div className="flex justify-end pt-5 pb-1 pr-5">
            <div className="p-2 cursor-pointer" onClick={props.onClose}>
              <img
                src={
                  props.alt
                    ? '/images/close-modal-dark.svg'
                    : '/images/close-modal.svg'
                }
                width="14"
                height="14"
                alt=""
              />
            </div>
          </div>
        )}

        <div className={clsx(styles['box-content'], props.boxClassName)}>
          {props.children}
        </div>
      </div>
    </>
  );
}

export default Modal;
