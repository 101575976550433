import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AdminContext from '../../../context/admin-context';
import Web3Balance from '../../shared/balance';
import DashboardBox from '../box';

function RaffleData() {
  const { t } = useTranslation('admin');
  const { raffleData } = useContext(AdminContext);

  return (
    <>
      {raffleData && (
        <DashboardBox className="space-y-1">
          <div>
            <span className="mr-1">{t('data.ethBalance')}</span>
            <span className="font-bold">
              <Web3Balance balance={raffleData.ether} currency="ether" />
            </span>
          </div>
          <div>
            <span className="mr-1">{t('data.raffleBalance')}</span>
            <span className="font-bold">
              <Web3Balance
                balance={raffleData.raffleTokens}
                currency="raffle"
              />
            </span>
          </div>
        </DashboardBox>
      )}
    </>
  );
}

export default RaffleData;
