import clsx from 'clsx';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: any;
}

export default function SubTitle(props: Props) {
  return (
    <div
      {...{
        ...props,
        className: clsx(
          'font-BroadwayFlat text-[2.5rem] text-shadow text-white text-center',
          props.className
        ),
      }}
    >
      {props.children}
    </div>
  );
}
