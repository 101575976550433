import {
  ContractTransactionResponse,
  JsonRpcProvider,
  JsonRpcSigner,
  ethers,
} from 'ethers';
import { HolderReflectionBalance } from '../interfaces/holder-reflection-balance';

const { abi } = require('./abi/RaffleToken.json');

export class RaffleToken {
  instance: ethers.Contract;

  constructor(instance: any) {
    this.instance = instance;
  }

  public async getReflectionDay(): Promise<bigint> {
    return await this.instance.getReflectionDay();
  }
  public async getBalancesPerDay(): Promise<bigint[]> {
    const iterable = await this.instance.getBalancesPerDay();
    const result: bigint[] = [];
    const keys = Object.keys(iterable).sort(
      (a, b) => parseInt(a) - parseInt(b)
    );

    for (const key of keys) {
      result.push(iterable[key]);
    }

    return result;
  }

  public async getHolderBalancesPerDay(
    holderAddress: string
  ): Promise<HolderReflectionBalance[]> {
    const iterable = await this.instance.getHolderBalancesPerDay(holderAddress);
    const result: HolderReflectionBalance[] = [];
    const keys = Object.keys(iterable).sort(
      (a, b) => parseInt(a) - parseInt(b)
    );

    for (const key of keys) {
      result.push({
        balance: iterable[key]['0'],
        payout: iterable[key]['1'],
        payedOut: iterable[key]['2'],
      });
    }

    return result;
  }

  public async getReflectionPool(): Promise<bigint> {
    return this.instance.reflectionPool();
  }
  public async getStartedOn(): Promise<bigint> {
    return this.instance.startedOn();
  }
  public async claimReflections(): Promise<ContractTransactionResponse> {
    return this.instance.claimReflections();
  }
  public async balanceOf(address: string): Promise<bigint> {
    return this.instance.balanceOf(address);
  }
  public async getUniswapV2Pair(): Promise<string> {
    return this.instance.uniswapV2Pair();
  }

  public async name(): Promise<string> {
    return this.instance.name();
  }
  public async decimals(): Promise<bigint> {
    return this.instance.decimals();
  }

  public async totalSupply(): Promise<bigint> {
    return this.instance.totalSupply();
  }

  public async startTrading(
    liquidity: bigint,
    liquidityEth: bigint,
    reflectionPool: bigint
  ): Promise<ContractTransactionResponse> {
    return this.instance.startTrading(liquidity, liquidityEth, reflectionPool, {
      value: liquidityEth,
    });
  }
}

// async function getBalancesPerDay() external view returns (uint256[] memory) {
//     return balancesPerDay;
// }

// async function getHolderBalancesPerDay(
//     address holder
// ) external view returns (HolderBalance[] memory) {
//     return holderBalancesPerDay[holder];
// }
// }

export const loadRaffleTokenContract = (
  signer: JsonRpcSigner | JsonRpcProvider,
  address?: string
) => {
  const instance = new ethers.Contract(
    address || process.env.REACT_APP_RAFFLE_TOKEN_ADDRESS!,
    abi,
    signer
  );

  return new RaffleToken(instance);
};
