import { useTranslation } from 'react-i18next';
import Accordion from '../../accordion';
import { AccordionItem } from '../../accordion/item';
import Section from '../../section';
import Title from '../../title';

export default function FaqsSection() {
  const { t } = useTranslation('home');

  const items = t('faqs.items', {
    returnObjects: true,
  }) as AccordionItem[];

  return (
    <>
      <Section id="faqs">
        <Title className="mb-5">{t('faqs.title')}</Title>

        <Accordion items={items} />
      </Section>
    </>
  );
}
