import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AdminContext from '../../../context/admin-context';
import { RaffleState } from '../../../interfaces/raffle';
import DashboardBox from '../box';
import CurrentRaffleItem from './item';

function CurrentRaffles() {
  const { t } = useTranslation('admin');
  const { raffles } = useContext(AdminContext);

  const items = raffles!.filter(
    (raffle) => raffle.state < RaffleState.COMPLETED
  );

  items.sort((a, b) => {
    return Number(a.cId) - Number(b.cId);
  });

  return (
    <DashboardBox className="overflow-auto">
      <div className="text-xl font-medium mb-2">{t('current.title')}</div>

      {items!.map((raffle) => (
        <CurrentRaffleItem raffle={raffle} />
      ))}
    </DashboardBox>
  );
}

export default CurrentRaffles;
