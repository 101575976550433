import { isAddress } from 'ethers';

export const delay = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const getChainScannerUrl = (value: string) => {
  if (isAddress(value)) {
    return process.env.REACT_APP_SCANNER_ADDRESS_URL!.replace(
      '{{ADDRESS}}',
      value
    );
  } else {
    return process.env.REACT_APP_SCANNER_TX_URL!.replace('{{TX}}', value);
  }
};

export const getUniswapUrl = `https://app.uniswap.org/swap?inputCurrency=ETH&outputCurrency=${process.env.REACT_APP_RAFFLE_TOKEN_ADDRESS}&chain=base`;

export const getUniswapLiquidityUrl = `https://app.uniswap.org/add/v2/ETH/${process.env.REACT_APP_RAFFLE_TOKEN_ADDRESS}?chain=base`;

export const batchPromiseHandler = async (
  batchSize: number,
  promises: (() => Promise<any>)[]
) => {
  const results = [];

  for (let i = 0; i < promises.length; i += batchSize) {
    const batchPromises = promises.slice(i, i + batchSize);
    const batchResults = await Promise.all(batchPromises.map((p) => p()));
    results.push(...batchResults);
  }

  return results;
};
