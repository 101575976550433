import { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getRaffles } from '../api/raffles';
import Notification from '../components/shared/notification';
import { loadRaffleContract } from '../contracts/raffle';
import { loadRaffleTokenContract } from '../contracts/raffle-token';
import { Raffle } from '../interfaces/raffle';
import { getPublicRpc } from '../utils/rpc-rotater';

export interface RaffleContractData {
  ether: bigint;
  raffleTokens: bigint;
}

interface AdminContextType {
  raffles?: Raffle[];
  raffleData?: RaffleContractData;
  setRaffles: (raffle: Raffle[]) => void;
  refresh: () => void;
}

const AdminContext = createContext<AdminContextType>({
  refresh: () => {},
  setRaffles: () => {},
});

export function AdminContextProvider(props: any) {
  const { t } = useTranslation('admin');
  const [raffleData, setRaffleData] = useState<
    RaffleContractData | undefined
  >();
  const [raffles, setRaffles] = useState<Raffle[] | undefined>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    setTimeout(() => {
      refresh();
    }, 250);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function refresh() {
    setError(undefined);
    try {
      // get raffles from backend
      const raffles = await getRaffles();
      console.log('raffles', raffles);
      setRaffles(raffles);

      const [ethBalance, reservedEther, tokenBalance, reservedTokens] =
        await Promise.all([
          getPublicRpc().getBalance(process.env.REACT_APP_RAFFLE_ADDRESS!),
          loadRaffleContract(getPublicRpc()).reservedEther(),
          loadRaffleTokenContract(getPublicRpc()).balanceOf(
            process.env.REACT_APP_RAFFLE_ADDRESS!
          ),
          loadRaffleContract(getPublicRpc()).reservedTokens(),
        ]);

      setRaffleData({
        ether: ethBalance - reservedEther,
        raffleTokens: tokenBalance - reservedTokens,
      });
    } catch (err) {
      console.error('failed to fetch raffles', err);
      setError(t('errors.fetch'));
    }
  }

  return (
    <AdminContext.Provider
      value={{
        raffles,
        refresh,
        setRaffles,
        raffleData,
      }}
    >
      {error && <Notification color="danger">{error}</Notification>}
      {raffles && props.children}
    </AdminContext.Provider>
  );
}

export default AdminContext;
