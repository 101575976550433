import clsx from 'clsx';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: any;
}

export default function Title(props: Props) {
  return (
    <div
      {...{
        ...props,
        className: clsx(
          'font-BroadwayFlat text-[4rem] text-shadow text-center text-gradient max-md:text-[3rem]',
          props.className
        ),
      }}
    >
      {props.children}
    </div>
  );
}
