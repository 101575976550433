import { BrowserProvider, ethers } from 'ethers';

import { createContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import truncateEthAddress from 'truncate-eth-address';
declare let window: any;

interface Web3ContextType {
  walletAddress?: string;
  enabled: boolean;
  correctNetwork?: boolean;
  provider?: BrowserProvider;
  connect: (hasToBeAddress?: string) => Promise<string>;
}

const Web3Context = createContext<Web3ContextType>({
  connect: {} as any,
  enabled: false,
});

export const metamaskErrors = ['noMetamask', 'wrongNetwork', 'invalidAddress'];

export function Web3ContextProvider(props: any) {
  const [enabled, setEnabled] = useState<boolean>(false);
  const [networkId, setNetworkId] = useState<string>();
  const [walletAddress, setWalletAddress] = useState<string | undefined>();
  const [provider, setProvider] = useState<BrowserProvider>();

  const { t } = useTranslation('common');

  useEffect(() => {
    if (!window.ethereum) return;

    const prov = new ethers.BrowserProvider(window.ethereum);

    setProvider(prov);
    const handleAccountsChanged = (accounts: string[]) => {
      // Update current account
      console.log('account change', accounts[0]);
      setWalletAddress(accounts[0] || undefined);
    };

    const handleNetworkChanged = (chainId: string) => {
      setNetworkId(parseInt(chainId, 16).toString());
    };

    window.ethereum.on('accountsChanged', handleAccountsChanged);
    window.ethereum.on('chainChanged', handleNetworkChanged);

    return () => {
      window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
      window.ethereum.removeListener('chainChanged', handleNetworkChanged);
    };
  }, []);

  async function connect(hasToBeAddress?: string) {
    let fetchedAccounts;

    if (!window.ethereum && !window.web3) {
      toast.error(t('web3.noMetamask'), { duration: 3000 });
      // eslint-disable-next-line no-throw-literal
      throw 'noMetamask';
    }

    await window.ethereum.enable();

    const currentNetId = +(await window.ethereum.request({
      method: 'eth_chainId',
    }));

    if (currentNetId.toString() !== process.env.REACT_APP_NETWORK_ID) {
      console.log('chain', currentNetId.toString());
      toast.error(t('web3.wrongNetwork'), { duration: 3000 });
      // eslint-disable-next-line no-throw-literal
      throw 'wrongNetwork';
    }

    setNetworkId(currentNetId.toString());

    fetchedAccounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });

    if (
      hasToBeAddress &&
      fetchedAccounts[0].toLowerCase() !== hasToBeAddress.toLowerCase()
    ) {
      toast.error(
        t('web3.invalidAddress', {
          address: truncateEthAddress(hasToBeAddress),
        }),
        {
          duration: 3000,
        }
      );
      // eslint-disable-next-line no-throw-literal
      throw 'invalidAddress';
    }

    setWalletAddress(fetchedAccounts[0]);
    setEnabled(true);

    return fetchedAccounts[0];
  }

  const correctNetwork = networkId === process.env.REACT_APP_NETWORK_ID;
  const showNetworkSticky = enabled && !correctNetwork;

  return (
    <Web3Context.Provider
      value={{
        connect,
        enabled,
        correctNetwork,
        walletAddress,
        provider,
      }}
    >
      {showNetworkSticky && (
        <div className="fixed left-0 right-0 text-center bg-danger z-10">
          {t('web3.wrongNetwork')}
        </div>
      )}
      {props.children}
    </Web3Context.Provider>
  );
}

export default Web3Context;
