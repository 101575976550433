import HTMLReactParser from 'html-react-parser';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import truncateEthAddress from 'truncate-eth-address';
import Web3Context, {
  metamaskErrors,
} from '../../../../../context/web3-context';
import { loadRaffleContract } from '../../../../../contracts/raffle';
import {
  RaffleItem,
  RafflePrizeType,
  RaffleState,
} from '../../../../../interfaces/raffle';
import { getPublicRpc } from '../../../../../utils/rpc-rotater';
import {
  batchPromiseHandler,
  getChainScannerUrl,
} from '../../../../../utils/utils';
import Button from '../../../../button';
import Modal from '../../../../modal';
import Web3Balance from '../../../../shared/balance';
import Notification from '../../../../shared/notification';
import { loadRaffles } from '../../../raffles';
import { getClaimPrizeError } from '../../../raffles/item';
import styles from './index.module.css';

export default function ClaimPrizeModal(props: { onClose: () => void }) {
  const { t } = useTranslation('home');
  const [status, setStatus] = useState<{
    state: 'loading' | 'won' | 'notWon' | 'error';
    raffle?: RaffleItem;
  }>({ state: 'loading' });
  const [loading, setLoading] = useState(false);
  const [transactionMessage, setTransactionMessage] = useState<
    { color: 'success' | 'danger' | 'neutral'; message: string } | undefined
  >();
  const { connect, walletAddress, provider } = useContext(Web3Context);

  useEffect(() => {
    checkForPrize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function checkForPrize() {
    try {
      const walletAddress = await connect();

      const raffles = await loadRaffles();

      const winningRaffles = (
        await batchPromiseHandler(
          +process.env.REACT_APP_RAFFLE_BATCH_SIZE!,
          raffles
            .filter((raffle) => raffle.state === RaffleState.COMPLETED)
            .map((raffle) => async () => {
              const prizeStatus = await loadRaffleContract(
                getPublicRpc()
              ).getRafflePrize(raffle.id, walletAddress);
              raffle.claimable = !prizeStatus.claimed
                ? prizeStatus.prize
                : undefined;
              return raffle;
            })
        )
      ).filter((raffle) => raffle.claimable);

      console.log('winning raffles', winningRaffles);

      if (winningRaffles.length > 0) {
        setStatus({ state: 'won', raffle: winningRaffles[0] });
      } else {
        setStatus({ state: 'notWon' });
      }
    } catch (err: any) {
      console.error('failed to check prize status', err);
      if (err.message.includes('execution reverted: "NO"')) {
        setStatus({ state: 'notWon' });
      } else if (!metamaskErrors.includes(err)) {
        setStatus({ state: 'error' });
      }
    }
  }

  async function onClaimPrize() {
    setLoading(true);
    setTransactionMessage(undefined);
    try {
      await connect();
      const signer = await provider!.getSigner();
      const contract = loadRaffleContract(signer!);

      const tx = await contract.claimRafflePrize(status.raffle!.id);
      setTransactionMessage({
        color: 'neutral',
        message: t('header.claimPrize.claiming', {
          url: getChainScannerUrl(tx.hash),
        }),
      });

      const receipt = await tx.wait();
      console.log('transaction was successfull', receipt);
      setTransactionMessage({
        color: 'success',
        message: t('header.claimPrize.claimed', {
          url: getChainScannerUrl(tx.hash),
        }),
      });
    } catch (err: any) {
      console.error('failed to claim', err);
      const error = getClaimPrizeError(t, err);

      if (error) {
        setTransactionMessage({
          color: 'danger',
          message: error,
        });
      }
      setLoading(false);
    }
  }

  return (
    <>
      {['loading', 'error', 'notWon'].includes(status.state) && (
        <Modal className="w-full max-w-[400px] text-xl" onClose={props.onClose}>
          <div>
            {t('header.claimPrize.' + status.state, {
              walletAddress: walletAddress
                ? truncateEthAddress(walletAddress)
                : '',
            })}
          </div>
        </Modal>
      )}
      {status.state === 'won' && (
        <Modal
          className="w-full max-w-[650px] !bg-transparent !bg-none"
          onClose={props.onClose}
          noCloseButton
        >
          <div className="relative">
            <img src="/images/prize.png" alt="Raffle" className="w-full" />
            <div className="absolute top-[48%] left-[18%] right-[21%]">
              {transactionMessage && (
                <Notification
                  className="!mb-0 mx-2"
                  color={transactionMessage.color}
                  disableScroll
                >
                  {HTMLReactParser(transactionMessage.message)}
                </Notification>
              )}
              <div className="text-[2rem] text-center">
                {t('header.claimPrize.wonTitle')}
              </div>
              <div className="font-BroadwayFlat text-[2.875rem] text-shadow text-center text-gradient leading-none mb-2">
                {status.raffle!.name}
              </div>
              <div className={styles.background}>
                <img
                  alt="prize"
                  src={
                    status.raffle!.prizeType === RafflePrizeType.ETHER
                      ? '/images/ether-small.svg'
                      : '/images/raffle-small.svg'
                  }
                />

                <span>
                  <Web3Balance balance={status.raffle!.claimable!} />
                </span>
              </div>
              <Button
                className="w-full mt-3 max-w-[80%] ml-[10%] mr-[10%]"
                gradient
                onClick={onClaimPrize}
                disabled={loading}
              >
                {t('header.claimPrize.claim')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
