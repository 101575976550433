import { AxiosError } from 'axios';
import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import myAxios from '../utils/my-axios';
import { loginRoute } from '../utils/routes';

interface AuthContextType {
  apiKey?: string;
  saveApiKey: (apiKey: string) => void;
  deleteApiKey: () => void;
}

const AuthContext = createContext<AuthContextType>({
  saveApiKey: () => {},
  deleteApiKey: () => {},
});

const localStorageKey = 'raffleApiKey';

export function AuthContextProvider(props: any) {
  const [apiKey, setApiKey] = useState<string | undefined>(
    localStorage.getItem(localStorageKey) || undefined
  );
  const navigate = useNavigate();

  // redirect to login form when an api request returns a session error
  useEffect(() => {
    myAxios.interceptors.response.clear();
    myAxios.interceptors.request.clear();

    if (apiKey) {
      myAxios.interceptors.request.use(
        (config) => {
          config.headers['x-api-key'] = apiKey;
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    }

    myAxios.interceptors.response.use(undefined, async (error: AxiosError) => {
      const err = (error.response!.data as any).error;

      if (
        !error.response!.request.responseURL.includes(
          '/admin/verify-admin-api-key'
        ) &&
        err === 'invalidApiKey'
      ) {
        deleteApiKey();
        navigate(loginRoute);
      }

      return Promise.reject(error);
    });
  }, [navigate, apiKey]);

  function saveApiKey(key: string) {
    setApiKey(key);
    localStorage.setItem(localStorageKey, key);
  }

  function deleteApiKey() {
    setApiKey(undefined);
    localStorage.removeItem(localStorageKey);
  }

  return (
    <AuthContext.Provider
      value={{
        apiKey,
        saveApiKey,
        deleteApiKey,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
