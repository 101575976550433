import parse from 'html-react-parser';

export interface AccordionItem {
  title: string;
  text: string;
  expanded?: boolean;
}

function AccordionItemEl(props: { item: AccordionItem; onToggle: () => void }) {
  return (
    <div className="px-5 py-3">
      <div
        className="flex items-center cursor-pointer"
        onClick={props.onToggle}
      >
        <p className="text-[1.75rem] text-white mr-auto">
          {parse(props.item.title)}
        </p>
        <p className="text-lg text-[#D6D6D6]">
          {props.item.expanded ? 'collapse' : 'expand'}
        </p>
      </div>
      {props.item.expanded && (
        <p className="text-2xl text-white pt-2">{parse(props.item.text)}</p>
      )}
    </div>
  );
}

export default AccordionItemEl;
