import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { verifyApiKey } from '../../api/admin';
import AdminDashboard from '../../components/admin';
import AdminButton from '../../components/admin-button';
import DashboardBox from '../../components/admin/box';
import FormGroup from '../../components/shared/form/group';
import FormLabel from '../../components/shared/form/label';
import PasswordFormInput from '../../components/shared/form/password-input';
import Notification from '../../components/shared/notification';
import AuthContext from '../../context/auth-context';
import NotAuthGuard from '../../guards/not-auth';
import { adminRoute } from '../../utils/routes';

const schema = yup
  .object()
  .shape({
    apiKey: yup.string().required(),
  })
  .required();

function LoginPage() {
  const { t } = useTranslation('login');
  const { saveApiKey } = useContext(AuthContext);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  async function onSubmit(values: any) {
    console.log('submitted', values);
    setLoading(true);
    setError(undefined);
    try {
      await verifyApiKey(values.apiKey);

      saveApiKey(values.apiKey);
      navigate(adminRoute);

      toast.success(t('success'), { duration: 3000 });
    } catch (err) {
      console.error('failed to login', err);
      if (axios.isAxiosError(err) && err.response!.status === 401) {
        setError(t('errors.invalidApiKey'));
      } else {
        setError(t('errors.unexpected'));
      }

      setValue('apiKey', '');
    }
    setLoading(false);
  }

  return (
    <NotAuthGuard>
      <AdminDashboard>
        <div className="flex justify-center">
          <DashboardBox className="w-full max-w-[500px] mt-24">
            {error && <Notification color="danger">{error}</Notification>}

            <div className="text-xl font-medium mb-6">{t('title')}</div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <FormLabel>{t('apiKey.label')}</FormLabel>
                <PasswordFormInput
                  {...register('apiKey')}
                  placeholder={t('apiKey.placeholder')}
                  disabled={loading}
                  error={errors.apiKey !== undefined}
                />
              </FormGroup>
              <AdminButton className="w-full" disabled={loading}>
                {t('submit')}
              </AdminButton>
            </form>
          </DashboardBox>
        </div>
      </AdminDashboard>
    </NotAuthGuard>
  );
}

export default LoginPage;
