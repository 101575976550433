import parse from 'html-react-parser';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../modal';

export default function Footer() {
  const { t } = useTranslation('home');
  const [show, setShow] = useState(false);

  return (
    <>
      <div className="bg-black bg-opacity-[0.15] py-5 px-4 flex flex-col items-center">
        <div className="flex space-x-6 justify-center mb-3">
          <a href={process.env.REACT_APP_TWITTER_URL}>
            <img src="/images/footer-twitter.svg" alt="twitter" />
          </a>
          <a href={process.env.REACT_APP_TELEGRAM_URL}>
            <img src="/images/footer-telegram.svg" alt="telegram" />
          </a>
        </div>
        <div className="text-[1.75rem] text-center">
          <span className="mr-2">{t('footer.text')}</span>
          <span
            className="underline cursor-pointer"
            onClick={() => setShow(true)}
          >
            {t('footer.learn')}
          </span>
        </div>
      </div>
      {show && (
        <Modal className="w-full max-w-[660px]" onClose={() => setShow(false)}>
          <div className="text-4xl mb-8">{parse(t('disclaimer.title'))}</div>
          <div className="text-[1.75rem] space-y-8">
            {parse(t('disclaimer.text'))}
          </div>
        </Modal>
      )}
    </>
  );
}
