import HTMLReactParser from 'html-react-parser';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { scroller } from 'react-scroll';
import Web3Context, { metamaskErrors } from '../../../../context/web3-context';
import { loadRaffleTokenContract } from '../../../../contracts/raffle-token';
import { HolderReflectionBalance } from '../../../../interfaces/holder-reflection-balance';
import { getPublicRpc } from '../../../../utils/rpc-rotater';
import { delay, getChainScannerUrl } from '../../../../utils/utils';
import Button from '../../../button';
import Modal from '../../../modal';
import Web3Balance from '../../../shared/balance';
import Notification from '../../../shared/notification';
import ReflectionItem from './item';

interface ReflectionContractData {
  reflectionDay: bigint;
  balancesPerDay: bigint[];
  holderBalancesPerDay: HolderReflectionBalance[];
  claimable: bigint;
  claimed: bigint;
  startedOn: bigint;
}

export const calculateReflectionPayout = (
  holderBalanceOfDay: bigint,
  balanceOfDay: bigint,
  reflectionPool: bigint,
  maxReflectionDays: bigint
) => {
  const percentage =
    balanceOfDay > 0
      ? (holderBalanceOfDay * BigInt(10 ** 27)) / balanceOfDay
      : BigInt(0);

  return ((reflectionPool / maxReflectionDays) * percentage) / BigInt(10 ** 27);
};

export default function ReflectionsModal(props: { onClose: () => void }) {
  const { t } = useTranslation('home');
  const { walletAddress, provider } = useContext(Web3Context);
  const [claiming, setClaiming] = useState(false);
  const [contractData, setContractData] = useState<ReflectionContractData>();
  const [claimMessage, setClaimMessage] = useState<
    { color: 'success' | 'danger' | 'neutral'; message: string } | undefined
  >();

  useEffect(() => {
    if (walletAddress) {
      loadContractData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletAddress]);

  async function loadContractData() {
    // const signer = await provider!.getSigner();
    const reflectionDay = await loadRaffleTokenContract(
      getPublicRpc()
    ).getReflectionDay();
    const balancesPerDay = await loadRaffleTokenContract(
      getPublicRpc()
    ).getBalancesPerDay();
    const holderBalancesPerDay = await loadRaffleTokenContract(
      getPublicRpc()
    ).getHolderBalancesPerDay(walletAddress!);
    const reflectionPool = await loadRaffleTokenContract(
      getPublicRpc()
    ).getReflectionPool();
    const startedOn = await loadRaffleTokenContract(
      getPublicRpc()
    ).getStartedOn();

    if (holderBalancesPerDay.length < 1) {
      holderBalancesPerDay.push({
        balance: BigInt(0),
        payout: BigInt(0),
        payedOut: false,
      });
    }

    // sync balances
    for (let i = balancesPerDay.length - 1; i < reflectionDay; i++) {
      balancesPerDay.push(balancesPerDay[balancesPerDay.length - 1]);
    }

    let claimed = BigInt(0);
    let claimable = BigInt(0);

    for (let i = 0; i < balancesPerDay.length; i++) {
      if (i > holderBalancesPerDay.length - 1) {
        holderBalancesPerDay.push({
          payedOut: false,
          payout: BigInt(0),
          balance:
            holderBalancesPerDay[holderBalancesPerDay.length - 1].balance,
        });
      }
      const holderBalance = holderBalancesPerDay[i];

      if (!holderBalance.payedOut) {
        holderBalance.payout = calculateReflectionPayout(
          holderBalance.balance,
          balancesPerDay[i],
          reflectionPool,
          BigInt(90)
        );

        if (i < reflectionDay) {
          claimable += holderBalance.payout;
        }
      } else if (holderBalancesPerDay[i].payedOut) {
        claimed += holderBalancesPerDay[i].payout;
      }
    }

    const data: ReflectionContractData = {
      reflectionDay,
      holderBalancesPerDay,
      balancesPerDay,
      claimable,
      claimed,
      startedOn,
    };

    setContractData(data);
    console.log('reload', data);

    // scroll to current reflection day
    await delay(500);
    scroller.scrollTo('reflection-' + reflectionDay.toString(), {
      duration: 100,
      containerId: 'reflections',
    });
  }

  async function onClaim() {
    setClaiming(true);
    setClaimMessage(undefined);

    try {
      const signer = await provider!.getSigner();
      const contract = loadRaffleTokenContract(signer!);
      const tx = await contract.claimReflections();
      setClaimMessage({
        color: 'neutral',
        message: t('tokenomics.reflectionsModal.claiming', {
          url: getChainScannerUrl(tx.hash),
        }),
      });

      const receipt = await tx.wait();
      console.log('transaction was successfull', receipt);
      setClaimMessage({
        color: 'success',
        message: t('tokenomics.reflectionsModal.success', {
          url: getChainScannerUrl(tx.hash),
        }),
      });
      loadContractData();
    } catch (err: any) {
      console.error('failed to claim', err);
      if (!metamaskErrors.includes(err)) {
        setClaimMessage({
          color: 'danger',
          message: t('tokenomics.reflectionsModal.failed'),
        });
      }
    }

    setClaiming(false);
  }

  function onClose() {
    if (!claiming) {
      props.onClose();
    }
  }

  return (
    <Modal className="w-full max-w-[400px]" onClose={onClose}>
      {!contractData && (
        <div className="text-xl">
          {t('tokenomics.reflectionsModal.loading')}
        </div>
      )}
      {contractData && (
        <>
          {claimMessage && (
            <Notification color={claimMessage.color} disableScroll>
              {HTMLReactParser(claimMessage.message)}
            </Notification>
          )}

          <div>{t('tokenomics.reflectionsModal.redeemed')}</div>
          <div className="text-[1.75rem] mb-2">
            <Web3Balance currency="raffle" balance={contractData.claimed} />
          </div>
          <div>{t('tokenomics.reflectionsModal.redeemable')}</div>
          <div className="text-4xl text-gradient inline">
            <Web3Balance currency="raffle" balance={contractData.claimable} />
          </div>

          <div className="my-7 bg-white h-[1px]"></div>

          <div id="reflections" className="max-h-[250px] overflow-scroll mb-4">
            <table className="border-collapse w-full text-left">
              <thead>
                <tr>
                  <td>{t('tokenomics.reflectionsModal.date')}</td>
                  <td>{t('tokenomics.reflectionsModal.reflection')}</td>
                </tr>
              </thead>
              <tbody>
                {Array.from(Array(90).keys()).map((i) => (
                  <ReflectionItem
                    key={'ref-' + i}
                    index={i}
                    holderBalances={contractData.holderBalancesPerDay}
                    startedOn={contractData.startedOn}
                  />
                ))}
              </tbody>
            </table>
          </div>

          <Button
            gradient
            className="w-full mb-2"
            onClick={onClaim}
            disabled={claiming || contractData.claimable <= 0}
          >
            {t('tokenomics.reflectionsModal.redeem')}
          </Button>
          <div className="text-center">
            {t('tokenomics.reflectionsModal.note')}
          </div>
        </>
      )}
    </Modal>
  );
}
