import clsx from 'clsx';
import { useEffect } from 'react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  color: 'danger' | 'success' | 'neutral';
  scrollContainerId?: string;
  disableScroll?: boolean;
}

const colors = {
  danger: { bg: 'bg-danger', color: 'text-white' },
  success: { bg: 'bg-success', color: 'text-white' },
  neutral: { bg: 'bg-black bg-opacity-10', color: 'auto' },
};

function Notification(props: Props) {
  let className = clsx(
    `p-4 text-lg rounded mb-5`,
    colors[props.color].bg,
    colors[props.color].color,
    props.className
  );

  useEffect(() => {
    if (props.disableScroll) return;

    if (props.scrollContainerId) {
      document
        .getElementById(props.scrollContainerId)!
        .scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.disableScroll]);

  return (
    <div {...props} className={className}>
      {props.children}
    </div>
  );
}

export default Notification;
