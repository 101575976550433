import adminDeploy from './admin-deploy.json';
import admin from './admin.json';
import common from './common.json';
import home from './home.json';
import login from './login.json';

const translations = {
  common,
  home,
  login,
  admin,
  // eslint-disable-next-line no-useless-computed-key
  ['admin-deploy']: adminDeploy,
};
export default translations;
