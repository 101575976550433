import clsx from 'clsx';
import styles from './index.module.css';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  image?: string;
  color?: 'purple';
  gradient?: boolean;
  disabled?: boolean;
}

function Button(props: Props) {
  let className = clsx(
    styles.button,
    styles.default,
    props.gradient ? styles.gradient : undefined,
    // props.gradient ? styles.gradient : styles.default,
    props.image ? 'flex pr-3.5' : 'pr-6',
    props.disabled ? 'opacity-50 pointer-events-none' : undefined,
    // props.gradient ? 'flex pr-3.5' : 'pr-6',
    props.className
  );

  return (
    <button {...props} className={className}>
      {props.gradient && (
        <span className="text-gradient">{props.children}</span>
      )}
      {!props.gradient && props.children}
      {props.image && (
        <img alt="icon" className="ml-auto w-8 h-8" src={props.image} />
      )}
    </button>
  );
}

export default Button;
