import { yupResolver } from '@hookform/resolvers/yup';
import { ethers } from 'ethers';
import HTMLReactParser from 'html-react-parser';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import Web3Context, { metamaskErrors } from '../../../context/web3-context';
import { loadRaffleTokenContract } from '../../../contracts/raffle-token';
import { DeployData } from '../../../pages/admin/deploy';
import { getChainScannerUrl } from '../../../utils/utils';
import AdminButton from '../../admin-button';
import FormGroup from '../../shared/form/group';
import FormInput from '../../shared/form/input';
import FormLabel from '../../shared/form/label';
import Notification from '../../shared/notification';

const schema = yup
  .object()
  .shape({
    liquidity: yup.number().moreThan(0).required(),
    liquidityEth: yup.number().moreThan(0).required(),
    reflectionPool: yup.number().moreThan(0).required(),
  })
  .required();

function AdminDeployTradingStatus(props: {
  data: DeployData;
  onChange: (status: boolean) => void;
}) {
  const { t } = useTranslation('admin-deploy');
  const [loading, setLoading] = useState(false);
  const { provider, connect } = useContext(Web3Context);
  const [transactionMessage, setTransactionMessage] = useState<
    { color: 'success' | 'danger' | 'neutral'; message: string } | undefined
  >();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  async function onSubmit(values: any) {
    setLoading(true);
    setTransactionMessage(undefined);
    try {
      await connect(process.env.REACT_APP_ADMIN_WALLET_ADDRESS);
      const signer = await provider!.getSigner();
      const contract = await loadRaffleTokenContract(signer!);

      // const totalSupply = await contract.totalSupply();
      const liquidity = ethers.parseEther(values.liquidity.toString());
      const liquidityEth = ethers.parseEther(values.liquidityEth.toString());
      const reflectionPool = ethers.parseEther(
        values.reflectionPool.toString()
      );
      console.log('supplies', { liquidity, liquidityEth, reflectionPool });

      const tx = await contract.startTrading(
        liquidity,
        liquidityEth,
        reflectionPool
      );
      setTransactionMessage({
        color: 'neutral',
        message: t('tradingStatus.starting', {
          url: getChainScannerUrl(tx.hash),
        }),
      });

      const receipt = await tx.wait();
      console.log('transaction was successfull', receipt);
      setTransactionMessage({
        color: 'success',
        message: t('tradingStatus.started', {
          url: getChainScannerUrl(tx.hash),
        }),
      });

      props.onChange(true);

      toast.success(t('success'), { duration: 3000 });
    } catch (err: any) {
      console.error('failed to start trading', err);
      if (!metamaskErrors.includes(err)) {
        setTransactionMessage({
          color: 'danger',
          message: t('tradingStatus.failed'),
        });
      }
    }
    setLoading(false);
  }

  return (
    <div>
      {transactionMessage && (
        <Notification color={transactionMessage.color} disableScroll>
          {HTMLReactParser(transactionMessage.message)}
        </Notification>
      )}
      <div>
        <span className="mr-1">{t('tradingStatus.status')}</span>
        {props.data.trading ? (
          <span className="text-success">{t('on')}</span>
        ) : (
          <span className="text-danger">{t('off')}</span>
        )}
      </div>
      {!props.data.trading && (
        <form onSubmit={handleSubmit(onSubmit)} className="mt-2">
          <div className="flex space-x-3">
            <FormGroup>
              <FormLabel>{t('tradingStatus.liquidity')}</FormLabel>
              <FormInput
                className="w-[200px]"
                {...register('liquidity')}
                error={errors.liquidity !== undefined}
                disabled={loading}
                placeholder={t('tradingStatus.tokens')}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>{t('tradingStatus.liquidityEth')}</FormLabel>
              <FormInput
                className="w-[200px]"
                {...register('liquidityEth')}
                error={errors.liquidity !== undefined}
                disabled={loading}
                placeholder="ETH"
              />
            </FormGroup>

            <FormGroup>
              <FormLabel>{t('tradingStatus.reflectionPool')}</FormLabel>
              <FormInput
                className="w-[200px]"
                {...register('reflectionPool')}
                error={errors.reflectionPool !== undefined}
                disabled={loading}
                placeholder={t('tradingStatus.tokens')}
              />
            </FormGroup>
          </div>
          <AdminButton disabled={loading}>
            {t('tradingStatus.start')}
          </AdminButton>
        </form>
      )}
    </div>
  );
}

export default AdminDeployTradingStatus;
