import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Web3Context from '../../../context/web3-context';
import Button from '../../button';
import Section from '../../section';
import Title from '../../title';
import ReflectionsModal from './reflections-modal';

export default function TokenomicsSection() {
  const { t } = useTranslation('home');
  const { connect } = useContext(Web3Context);
  const [showModal, setShowModal] = useState(false);

  async function onShowReflections() {
    try {
      await connect();
      setShowModal(true);
    } catch (err) {
      console.error('failed to connect', err);
    }
  }

  return (
    <>
      <Section id="tokenomics">
        <Title>{t('tokenomics.title')}</Title>

        <div className="flex justify-center mt-[-80px] mb-[-50px] max-lg:mt-0 max-lg:mb-0">
          <img
            alt="tokenomics"
            src="/images/tokenomics.png"
            className="max-w-[904px] w-full"
          />
        </div>
        <div className="flex justify-center">
          <Button gradient onClick={onShowReflections}>
            {t('tokenomics.reflections')}
          </Button>
        </div>
      </Section>
      <div className="separator"></div>
      {showModal && <ReflectionsModal onClose={() => setShowModal(false)} />}
    </>
  );
}
