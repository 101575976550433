import clsx from 'clsx';
import { useState } from 'react';
import AccordionItemEl, { AccordionItem } from './item';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  items: AccordionItem[];
}

function Accordion(props: Props) {
  const [accordionItems, setAccordionItems] = useState<AccordionItem[]>(
    props.items
  );

  function onToggle(index: number) {
    setAccordionItems(
      accordionItems.map((item, i) => {
        if (i === index) return { ...item, expanded: !item.expanded };

        return item;
      })
    );
  }

  const els = accordionItems.map((item, i) => {
    return (
      <AccordionItemEl
        key={'acc-' + item.title}
        item={item}
        onToggle={() => onToggle(i)}
      />
    );
  });

  return (
    <div
      {...{
        ...props,
        className: clsx(
          props.className,
          'border-2 border-white bg-white bg-opacity-10 rounded-[19px] [&>*:not(:last-child)]:border-b-2 [&>*:not(:last-child)]:border-white'
        ),
        items: undefined,
      }}
    >
      {els}
    </div>
  );
}

export default Accordion;
