import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Raffle, RaffleState } from '../../../../interfaces/raffle';
import AdminButton from '../../../admin-button';
import RaffleForm from '../../raffle-form';
import RaffleWinnersModal from '../winners';

function PastRaffleItem(props: { raffle: Raffle }) {
  const { t } = useTranslation('admin');

  const [showWinners, setShowWinners] = useState(false);

  return (
    <div className="py-4 border-b border-[#E7E7E7]">
      <RaffleForm
        key={props.raffle.PK}
        raffle={props.raffle}
        loading
      ></RaffleForm>
      <div className="flex items-center mt-3 space-x-4">
        <div className="mr-auto">
          {t('current.participants', { num: props.raffle.participants })}
        </div>
        {props.raffle.state === RaffleState.NO_PARTICIPANTS && (
          <AdminButton className="w-[200px]" disabled>
            {t('past.noParticipants')}
          </AdminButton>
        )}

        {props.raffle.state === RaffleState.COMPLETED && (
          <AdminButton
            className="w-[200px]"
            onClick={() => setShowWinners(true)}
          >
            {t('past.showWinners')}
          </AdminButton>
        )}
        {showWinners && (
          <RaffleWinnersModal
            raffle={props.raffle}
            onClose={() => setShowWinners(false)}
          />
        )}
      </div>
    </div>
  );
}

export default PastRaffleItem;
