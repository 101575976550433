import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { ethers } from 'ethers';
import { Raffle, raffleTypes } from '../../../interfaces/raffle';
import AdminButton from '../../admin-button';
import DateFormInput from '../../shared/form/date-input';
import FormGroup from '../../shared/form/group';
import FormInput from '../../shared/form/input';
import FormLabel from '../../shared/form/label';
import FormSelect from '../../shared/form/select';
import RaffleFormPrizeShares from './prize-shares';
import FormCheckbox from '../../shared/form/checkbox';

const schema = yup
  .object()
  .shape({
    name: yup.string().required(),
    type: yup.string().required(),
    drawingAt: yup.date().required(),
    prize: yup.number().moreThan(0).required(),
    prizeType: yup.string().required(),
    minParticipationAmount: yup.number().moreThan(0).required(),
    maxParticipationAmount: yup.number().moreThan(0).required(),
    otherTokenAsMin: yup.bool().default(false),
    otherTokenAddress: yup.string().when((values, schema, options) => {
      return options.parent.otherTokenAsMin === true
        ? yup
            .string()
            .required()
            .test('address', 'Invalid address', (value) =>
              ethers.isAddress(value)
            )
        : yup.mixed();
    }),
    otherTokenBalance: yup.number().when((values, schema, options) => {
      return options.parent.otherTokenAsMin === true
        ? yup.number().moreThan(0).required()
        : yup.mixed();
    }),
    otherTokenDecimals: yup.number().when((values, schema, options) => {
      return options.parent.otherTokenAsMin === true
        ? yup.number().integer().required()
        : yup.mixed();
    }),
    prizeShares: yup
      .array()
      .of(yup.number().integer().required())
      .min(1) // At least one item
      .test('sum', 'Sum of array items must be exactly 100', (value: any) => {
        try {
          const sum = value.reduce(
            (acc: number, curr: number) => acc + curr,
            0
          );

          return sum === 100;
        } catch (err) {
          return false;
        }
      }),
  })
  .required();

function RaffleForm(props: {
  onSubmit?: (values: any) => void;
  loading?: boolean;
  raffle?: Raffle;
}) {
  const { t } = useTranslation('admin');
  const commonTranslations = useTranslation('common');

  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: { otherTokenBalance: 1, otherTokenDecimals: 0 },
  });
  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
  } = methods;

  const minDate = new Date(new Date().getTime());

  useEffect(() => {
    if (props.raffle) {
      setValue('name', props.raffle.name);
      setValue('type', props.raffle.raffleType.toString());
      setValue('drawingAt', new Date(props.raffle.drawingAt));
      setValue(
        'prize',
        parseFloat(ethers.formatEther(BigInt(props.raffle.prize)))
      );
      setValue('prizeType', props.raffle.prizeType.toString());
      setValue('prizeShares', props.raffle.prizeShares);
      setValue(
        'minParticipationAmount',
        parseFloat(
          ethers.formatEther(BigInt(props.raffle.minParticipationAmount))
        )
      );
      setValue(
        'maxParticipationAmount',
        parseFloat(
          ethers.formatEther(BigInt(props.raffle.maxParticipationAmount))
        )
      );

      if (props.raffle.minParticipationTokenContract !== ethers.ZeroAddress) {
        setValue('otherTokenAsMin', true);

        setValue(
          'otherTokenAddress',
          props.raffle.minParticipationTokenContract
        );

        setValue(
          'otherTokenBalance',
          parseFloat(
            ethers.formatEther(
              BigInt(props.raffle.minParticipationTokenBalance)
            )
          )
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.raffle]);

  const [otherTokenAsMin] = watch(['otherTokenAsMin']);

  // console.log('errors', errors);
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(props.onSubmit ? props.onSubmit : () => {})}>
        <div className="flex space-x-7">
          <FormGroup className="w-full max-w-[300px]">
            <FormLabel>{t('form.name')}</FormLabel>
            <FormInput
              {...register('name')}
              disabled={props.loading}
              error={errors.name !== undefined}
            />
          </FormGroup>
          <FormGroup>
            <FormLabel>{t('form.type')}</FormLabel>
            <FormSelect
              className="w-[150px]"
              {...register('type')}
              disabled={props.loading}
              error={errors.type !== undefined}
            >
              {raffleTypes.map((type) => (
                <option value={type}>
                  {commonTranslations.t('raffleTypes.' + type)}
                </option>
              ))}
            </FormSelect>
          </FormGroup>
          <FormGroup className="flex-shrink-0">
            <FormLabel>{t('form.drawingAt')}</FormLabel>

            <Controller
              render={({ field }) => (
                <DateFormInput
                  value={field.value}
                  onChangeDate={field.onChange}
                  error={errors.drawingAt !== undefined}
                  minDate={minDate}
                  disabled={props.loading}
                  showTimeInput
                />
              )}
              control={control}
              name="drawingAt"
            />
          </FormGroup>
        </div>
        <div className="flex space-x-7">
          <FormGroup className="flex-shrink-0">
            <FormLabel>{t('form.minParticipationAmount')}</FormLabel>
            <FormInput
              {...register('minParticipationAmount')}
              disabled={props.loading}
              placeholder={t('form.amountRaffle')}
              error={errors.minParticipationAmount !== undefined}
            />
          </FormGroup>
          <FormGroup className="flex-shrink-0">
            <FormLabel>{t('form.maxParticipationAmount')}</FormLabel>
            <FormInput
              {...register('maxParticipationAmount')}
              disabled={props.loading}
              placeholder={t('form.amountRaffle')}
              error={errors.maxParticipationAmount !== undefined}
            />
          </FormGroup>
          <FormGroup className="flex-shrink-0">
            <FormLabel>{t('form.prize')}</FormLabel>
            <div className="flex space-x-3">
              <FormInput
                {...register('prize')}
                disabled={props.loading}
                placeholder={t('form.amount')}
                error={errors.prize !== undefined}
              />
              <FormSelect
                className="w-[80px]"
                {...register('prizeType')}
                disabled={props.loading}
                error={errors.prizeType !== undefined}
              >
                <option value="0">ETH</option>
                <option value="1">R</option>
              </FormSelect>
            </div>
          </FormGroup>
          <RaffleFormPrizeShares disabled={props.loading} />
        </div>
        <FormGroup className="items-start">
          <Controller
            render={({ field }) => (
              <FormCheckbox
                checkboxProps={{
                  onChange: (ev) => field.onChange(ev.target.checked),
                  checked: field.value === true,
                  name: 'otherTokenAsMin',
                  disabled: props.loading,
                }}
              >
                {t('form.otherTokenAsMin')}
              </FormCheckbox>
            )}
            control={control}
            name="otherTokenAsMin"
          />
        </FormGroup>

        {otherTokenAsMin && (
          <div className="flex space-x-7">
            <FormGroup className="w-full max-w-[400px]">
              <FormLabel>{t('form.otherTokenAddress')}</FormLabel>
              <FormInput
                {...register('otherTokenAddress')}
                placeholder="0x..."
                disabled={props.loading}
                error={errors.otherTokenAddress !== undefined}
              />
            </FormGroup>
            <FormGroup className="flex-shrink-0">
              <FormLabel>{t('form.otherTokenBalance')}</FormLabel>
              <FormInput
                {...register('otherTokenBalance', { valueAsNumber: true })}
                disabled={props.loading}
                placeholder={t('form.amount')}
                error={errors.otherTokenBalance !== undefined}
              />
            </FormGroup>
            {!props.raffle && (
              <FormGroup className="flex-shrink-0">
                <FormLabel>{t('form.otherTokenDecimals')}</FormLabel>
                <FormInput
                  {...register('otherTokenDecimals', { valueAsNumber: true })}
                  disabled={props.loading}
                  error={errors.otherTokenDecimals !== undefined}
                />
              </FormGroup>
            )}
          </div>
        )}
        {props.onSubmit && (
          <div className="flex justify-end">
            <AdminButton className="w-[200px] mt-3" disabled={props.loading}>
              {t('form.start')}
            </AdminButton>
          </div>
        )}
      </form>
    </FormProvider>
  );
}

export default RaffleForm;
