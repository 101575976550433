import { ethers } from 'ethers';

export const formatBalance = (balance: bigint) => {
  const formatted = ethers.formatEther(balance);
  const result = formatted.substring(0, formatted.indexOf('.') + 5);

  return result.split('').filter((r) => r !== '0' && r !== '.').length < 1
    ? '0'
    : result;
};

export default function Web3Balance(props: {
  balance: bigint;
  currency?: 'raffle' | 'ether';
}) {
  return (
    <>
      {props.currency && (
        <span className="pr-1">
          {props.currency === 'raffle' ? 'R' : 'ETH'}
        </span>
      )}
      <span>{formatBalance(props.balance)}</span>
    </>
  );
}
