import { Raffle, RaffleWinner } from '../interfaces/raffle';
import myAxios from '../utils/my-axios';

export const syncRaffle = async (id: string): Promise<Raffle> => {
  const { data } = await myAxios.post(`/raffles/${id}/sync`, {
    id,
  });

  return data;
};

export const getRaffles = async (): Promise<Raffle[]> => {
  const { data } = await myAxios.get(`/raffles`);

  return data;
};

export const setRaffleWinners = async (
  id: string,
  winners: RaffleWinner[]
): Promise<void> => {
  const { data } = await myAxios.post(`/raffles/${id}/set-winners`, {
    winners,
  });

  return data;
};
