import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import HTMLReactParser from 'html-react-parser';
import AdminContext from '../../../../context/admin-context';
import Web3Context, { metamaskErrors } from '../../../../context/web3-context';
import { loadRaffleContract } from '../../../../contracts/raffle';
import { Raffle, RaffleState } from '../../../../interfaces/raffle';
import { getChainScannerUrl } from '../../../../utils/utils';
import AdminButton from '../../../admin-button';
import Notification from '../../../shared/notification';
import RaffleForm from '../../raffle-form';

function CurrentRaffleItem(props: { raffle: Raffle }) {
  const { t } = useTranslation('admin');

  const [loading, setLoading] = useState(false);
  const { provider, connect } = useContext(Web3Context);
  const [transactionMessage, setTransactionMessage] = useState<
    { color: 'success' | 'danger' | 'neutral'; message: string } | undefined
  >();
  const { refresh } = useContext(AdminContext);

  async function onBump() {
    setLoading(true);
    setTransactionMessage(undefined);
    try {
      await connect(process.env.REACT_APP_ADMIN_WALLET_ADDRESS);
      const signer = await provider!.getSigner();
      const contract = loadRaffleContract(signer!);

      const tx = await contract.bumpRaffleDrawing(BigInt(props.raffle.cId));
      setTransactionMessage({
        color: 'neutral',
        message: t('current.bumping', {
          url: getChainScannerUrl(tx.hash),
        }),
      });

      const receipt = await tx.wait();
      console.log('transaction was successfull', receipt);
      setTransactionMessage({
        color: 'success',
        message: t('current.bumped', {
          url: getChainScannerUrl(tx.hash),
        }),
      });

      await refresh();

      toast.success(t('success'), { duration: 3000 });
    } catch (err: any) {
      console.error('failed to bzmp', err);
      if (!metamaskErrors.includes(err)) {
        setTransactionMessage({
          color: 'danger',
          message: t('current.errors.bump'),
        });
      }
    }
    setLoading(false);
  }

  const currentDate = Date.now();

  return (
    <div className="py-4 border-b border-[#E7E7E7]">
      {transactionMessage && (
        <Notification color={transactionMessage.color} disableScroll>
          {HTMLReactParser(transactionMessage.message)}
        </Notification>
      )}

      <RaffleForm
        key={props.raffle.PK}
        raffle={props.raffle}
        loading
      ></RaffleForm>
      <div className="flex items-center mt-3">
        <div className="mr-auto">
          {t('current.participants', { num: props.raffle.participants })}
        </div>
        {props.raffle.state === RaffleState.ACTIVE && (
          <AdminButton
            className="w-[200px]"
            disabled={
              loading ||
              currentDate < new Date(props.raffle.drawingAt).getTime()
            }
            onClick={onBump}
          >
            {t('current.bump')}
          </AdminButton>
        )}

        {props.raffle.state === RaffleState.DRAWING && (
          <AdminButton className="w-[200px]" disabled>
            {t('current.drawing')}
          </AdminButton>
        )}
      </div>
    </div>
  );
}

export default CurrentRaffleItem;
