import { useTranslation } from 'react-i18next';
import { scroller } from 'react-scroll';

// export default function Navbar() {
//   const { t } = useTranslation('common');
//   const [show, setShow] = useState(false);

//   return (
//     <>
//       <div
//         className="absolute top-4 right-10 text-[1.75rem] underline cursor-pointer"
//         onClick={() => setShow(true)}
//       >
//         {t('disclaimer.title')}
//       </div>
//       {show && (
//         <Modal className="w-[660px]" onClose={() => setShow(false)}>
//           <div className="text-4xl mb-8">{parse(t('disclaimer.title'))}</div>
//           <div className="text-[1.75rem] space-y-8">
//             {parse(t('disclaimer.text'))}
//           </div>
//         </Modal>
//       )}
//     </>
//   );
// }
export default function Navbar() {
  const { t } = useTranslation('home');

  function onItemClick(scrollTo: string) {
    scroller.scrollTo(scrollTo, {
      duration: 500,
      smooth: 'easeInOutQuart',
    });
  }

  const items = (
    t('navbar.items', {
      returnObjects: true,
    }) as { label: string; link: string }[]
  ).map((item) => (
    <div
      key={item.link}
      className="text-[1.75rem] cursor-pointer"
      onClick={() => onItemClick(item.link)}
    >
      {item.label}
    </div>
  ));

  return (
    <div className="px-16 py-4 flex items-center bg-black bg-opacity-[0.15] max-lg:px-8">
      <div className="flex-1">
        {/* <Logo /> */}
        <img src="/images/logo.svg" alt="logo" className="translate-y-1" />
      </div>
      <div className="flex space-x-14 max-lg:hidden">{items}</div>
      <div className="flex-1 flex items-center justify-end space-x-5">
        <a href={process.env.REACT_APP_TWITTER_URL}>
          <img src="/images/navbar-twitter.svg" alt="twitter" />
        </a>
        <a href={process.env.REACT_APP_TELEGRAM_URL}>
          <img src="/images/navbar-telegram.svg" alt="telegram" />
        </a>
      </div>
    </div>
  );
}
