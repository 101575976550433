import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import Section from '../../section';
import Title from '../../title';
import AboutSectionBox from './box';

export default function AboutSection() {
  const { t } = useTranslation('home');

  return (
    <>
      <Section id="about">
        <div className="flex">
          <div className="flex-1 max-lg:hidden">
            <AboutSectionBox />
          </div>
          <div className="flex-1">
            <Title>{t('about.title')}</Title>

            <p className="text-[1.75rem] leading-[33.2px]">
              {parse(t('about.text'))}
            </p>
          </div>
        </div>
      </Section>
      <div className="separator"></div>
    </>
  );
}
