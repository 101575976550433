import clsx from 'clsx';
import React from 'react';

export interface FormInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
}

const FormInput = React.forwardRef((props: FormInputProps, ref?: any) => {
  let className = clsx(
    'border outline-none p-2.5 px-3 text-[#121212] rounded-md placeholder:text-[#6F6F6F]',
    props.error ? 'border-red-500 bg-red-100' : 'border-[#6F6F6F]',
    props.className
  );

  return (
    <input {...{ ...props, error: undefined }} className={className} ref={ref}>
      {props.children}
    </input>
  );
});

export default FormInput;
