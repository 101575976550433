import clsx from 'clsx';
import dateFormat from 'dateformat';
import { HolderReflectionBalance } from '../../../../../interfaces/holder-reflection-balance';
import Web3Balance from '../../../../shared/balance';

export default function ReflectionItem(props: {
  index: number;
  holderBalances: HolderReflectionBalance[];
  startedOn: bigint;
}) {
  const date = new Date((Number(props.startedOn) + props.index * 86400) * 1000);
  const holderBalance =
    props.index <= props.holderBalances.length - 1
      ? props.holderBalances[props.index]
      : undefined;

  return (
    <tr
      id={'reflection-' + props.index}
      className={clsx(
        'text-[1.75rem]',
        holderBalance && holderBalance.payedOut ? 'opacity-50' : undefined
      )}
    >
      <td>{dateFormat(date, 'dd.mm.yyyy')}</td>
      <td>
        {holderBalance ? (
          <Web3Balance currency="raffle" balance={holderBalance.payout} />
        ) : (
          '-'
        )}
      </td>
    </tr>
  );
}
