import { ethers } from 'ethers';

let lastUsedRpcIndex = 0;

export const rpcs = process.env.REACT_APP_RPC_PROVIDERS!.split(',');

export const getPublicRpc = () => {
  if (lastUsedRpcIndex < rpcs.length - 1) {
    lastUsedRpcIndex++;
  } else {
    lastUsedRpcIndex = 0;
  }

  console.log('use', rpcs[lastUsedRpcIndex], lastUsedRpcIndex);

  return new ethers.JsonRpcProvider(rpcs[lastUsedRpcIndex]);
};
