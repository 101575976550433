export type CurrencyType = 'ether' | 'raffle';

export enum RaffleState {
  ACTIVE = 1,
  DRAWING,
  COMPLETED,
  NO_PARTICIPANTS,
}

export enum RafflePrizeType {
  ETHER,
  RAFFLE,
}

export type RaffleType = 'weekly' | 'monthly' | 'grand' | 'surprise';

export const raffleTypes = ['weekly', 'monthly', 'grand', 'surprise'];

export interface Raffle {
  PK: string;
  cId: string;
  state: RaffleState;
  name: string;
  raffleType: RaffleType;
  drawingAt: number;
  prize: string;
  prizeShares: number[];
  prizeType: RafflePrizeType;
  prizeLeft: string;
  participants: string;
  minParticipationAmount: string;
  maxParticipationAmount: string;
  minParticipationTokenContract: string;
  minParticipationTokenBalance: string;
  claimDeadline?: string;
  ranges?: string[];
  claimed?: number;
  claimable?: string;
  winners?: RaffleWinner[];
}

export interface RaffleWinner {
  address: string;
  prize: string;
  claimed: boolean;
}

export interface RafflePrizeStatus {
  prize: bigint;
  claimed: boolean;
}

export interface RaffleFromContract {
  id: bigint;
  name: string;
  raffleType: RaffleType;
  state: RaffleState;
  drawingAt: Date;
  prize: bigint;
  prizeType: RafflePrizeType;
  prizeLeft: bigint;
  participationNum: bigint;
  participants: bigint;
  minParticipationAmount: bigint;
  claimDeadline?: Date;
  exists: boolean;
}

export interface RaffleItem extends RaffleFromContract {
  claimed?: bigint;
  claimable?: bigint;
}

export interface RaffleMinParticipationToken {
  tokenContract: string;
  balance: bigint;
}
