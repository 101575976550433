import clsx from 'clsx';
import ClaimPrize from '../claim-prize';
import styles from './index.module.css';

export default function HomeSectionPot() {
  return (
    <div className="h-full">
      <div className="relative h-full pointer-events-none">
        <img alt="pot" className={styles.shadow} src="/images/pot-shadow.png" />
        <img
          alt="pot"
          className={styles.rainbow}
          src="/images/pot-rainbow.png"
        />
        <img
          alt="pot"
          className={styles['pot-coins']}
          src="/images/pot-coins.png"
        />
        <img
          alt="pot"
          className={clsx(styles.coin, styles['coin-8'])}
          src="/images/raffle-coin.png"
        />
        <img
          alt="pot"
          className={clsx(styles.coin, styles['coin-7'])}
          src="/images/raffle-coin.png"
        />
        <img
          alt="pot"
          className={clsx(styles.coin, styles['coin-6'])}
          src="/images/raffle-coin.png"
        />
        <img
          alt="pot"
          className={clsx(styles.coin, styles['coin-5'])}
          src="/images/raffle-coin.png"
        />
        <img
          alt="pot"
          className={clsx(styles.coin, styles['coin-4'])}
          src="/images/ethereum-coin.png"
        />
        <img
          alt="pot"
          className={clsx(styles.coin, styles['coin-3'])}
          src="/images/ethereum-coin.png"
        />
        <img
          alt="pot"
          className={clsx(styles.coin, styles['coin-2'])}
          src="/images/ethereum-coin.png"
        />
        <img
          alt="pot"
          className={clsx(styles.coin, styles['coin-1'])}
          src="/images/ethereum-coin.png"
        />
        <img alt="pot" className={styles.pot} src="/images/pot.png" />
      </div>
      <div className="absolute left-0 right-0 bottom-0 flex justify-center">
        <ClaimPrize />
      </div>
    </div>
  );
}
