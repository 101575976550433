import clsx from 'clsx';
import React from 'react';
import DatePicker from 'react-datepicker';
import IconInputWrapper from '../icon-input-wrapper';
import FormInput from '../input';
import Icon from './icon.svg';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  value?: Date;
  error?: boolean;
  disabled?: boolean;
  showTimeInput?: boolean;
  minDate: Date;
  onChangeDate: (date: Date) => void;
}

// interface Props extends React.InputHTMLAttributes<HTMLInputElement> {}

function DateFormInput(props: Props) {
  const DateInput = (values: any) => {
    return (
      <IconInputWrapper
        icon={Icon}
        // onIconClick={onToggle}
        iconClassName="top-3.5 right-3.5"
        onClick={values.onClick}
      >
        <FormInput
          {...{ ...props, className: clsx('w-full', props.className) }}
          value={values.value}
          error={props.error}
          disabled={props.disabled}
          onChange={(e) => values.onChange(e.target.value)}
        />
      </IconInputWrapper>
    );
  };

  return (
    <DatePicker
      className="w-full"
      selected={props.value}
      minDate={props.minDate}
      onChange={props.onChangeDate}
      customInput={<DateInput />}
      disabled={props.disabled}
      showTimeInput={props.showTimeInput}
      shouldCloseOnSelect={false}
      dateFormat={props.showTimeInput ? 'dd.MM.yyyy - HH:mm' : 'dd.MM.yyyy'}
    />
  );
}

export default DateFormInput;
