import Footer from '../../components/footer';
import AboutSection from '../../components/home/about';
import FaqsSection from '../../components/home/faqs';
import HomeSection from '../../components/home/header';
import CountdownSection from '../../components/home/raffles';
import TokenomicsSection from '../../components/home/tokenomics';

function HomePage() {
  return (
    <>
      <HomeSection />
      <AboutSection />
      <CountdownSection />
      <TokenomicsSection />
      <FaqsSection />
      <Footer />
    </>
  );
}

export default HomePage;
