import AdminDashboard from '../../components/admin';
import CurrentRaffles from '../../components/admin/current';
import PastRaffles from '../../components/admin/past';
import RaffleData from '../../components/admin/raffle-data';
import StartRaffle from '../../components/admin/start';
import { AdminContextProvider } from '../../context/admin-context';
import AuthGuard from '../../guards/auth';

function AdminPage() {
  return (
    <AuthGuard>
      <AdminDashboard>
        <div className="flex justify-center">
          <div className="w-full max-w-[1024px]">
            <AdminContextProvider>
              <div className="space-y-6">
                <RaffleData />
                <StartRaffle />
                <CurrentRaffles />
                <PastRaffles />
              </div>
            </AdminContextProvider>
          </div>
        </div>
      </AdminDashboard>
    </AuthGuard>
  );
}

export default AdminPage;
