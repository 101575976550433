import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import {
  Raffle,
  RafflePrizeType,
  RaffleWinner,
} from '../../../../../interfaces/raffle';
import Web3Balance from '../../../../shared/balance';

export default function RaffleWinnerItem(props: {
  raffle: Raffle;
  item: RaffleWinner;
  index: number;
}) {
  const { t } = useTranslation('admin');

  return (
    <tr>
      <td className={clsx(props.index === 0 ? 'pt-2' : undefined)}>
        {props.item.address}
      </td>
      <td>
        <Web3Balance
          currency={
            props.raffle.prizeType === RafflePrizeType.ETHER
              ? 'ether'
              : 'raffle'
          }
          balance={BigInt(props.item.prize)}
        />
      </td>
      <td>
        {props.item.claimed ? (
          <span className="text-success">{t('past.winners.claimed')}</span>
        ) : (
          <span className="text-danger">{t('past.winners.notClaimed')}</span>
        )}
      </td>
    </tr>
  );
}
