import clsx from 'clsx';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  size?: 'normal' | 'large';
}

export default function Logo(props: Props) {
  const size = props.size || 'normal';

  const signClassName = clsx(
    'text-white font-Aldrich text-shadow leading-none',
    size === 'normal'
      ? 'text-xl pt-2 mr-1'
      : 'text-[74px] pt-5 mr-3 max-md:text-[55px]'
  );

  const textClassName = clsx(
    'font-BroadwayFlat text-shadow text-gradient leading-none',
    size === 'normal' ? 'text-[40px]' : 'text-[128px] max-md:text-[99px]'
  );

  return (
    <div
      {...{
        ...props,
        className: clsx('flex', props.className),
        size: undefined,
      }}
    >
      <p className={signClassName}>$</p>
      <p className={textClassName}>RAFFLE</p>
    </div>
  );
}
