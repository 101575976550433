import { ethers } from 'ethers';
import HTMLReactParser from 'html-react-parser';
import { useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { syncRaffle } from '../../../api/raffles';
import AdminContext from '../../../context/admin-context';
import Web3Context, { metamaskErrors } from '../../../context/web3-context';
import { loadRaffleContract } from '../../../contracts/raffle';
import { getChainScannerUrl } from '../../../utils/utils';
import Notification from '../../shared/notification';
import DashboardBox from '../box';
import RaffleForm from '../raffle-form';

function StartRaffle() {
  const { t } = useTranslation('admin');
  const [loading, setLoading] = useState(false);
  const { provider, connect } = useContext(Web3Context);
  const [startMessage, setStartMessage] = useState<
    { color: 'success' | 'danger' | 'neutral'; message: string } | undefined
  >();
  const { refresh } = useContext(AdminContext);

  async function onSubmit(values: any) {
    console.log('submitted', values);
    setLoading(true);
    setStartMessage(undefined);
    try {
      await connect(process.env.REACT_APP_ADMIN_WALLET_ADDRESS);

      const signer = await provider!.getSigner();
      const contract = loadRaffleContract(signer!);

      const tx = await contract.startRaffle(
        values.name,
        values.type,
        values.prizeShares,
        values.drawingAt,
        ethers.parseEther(values.prize.toString()),
        +values.prizeType,
        ethers.parseEther(values.minParticipationAmount.toString()),
        ethers.parseEther(values.maxParticipationAmount.toString()),
        values.otherTokenAsMin ? values.otherTokenAddress : ethers.ZeroAddress,
        values.otherTokenAsMin
          ? BigInt(values.otherTokenBalance) *
              (values.otherTokenDecimals > 0
                ? BigInt(Math.pow(10, values.otherTokenDecimals))
                : BigInt(1))
          : BigInt(0)
      );
      console.log('started!!');
      setStartMessage({
        color: 'neutral',
        message: t('start.starting', {
          url: getChainScannerUrl(tx.hash),
        }),
      });

      const receipt = await tx.wait();
      console.log('transaction was successfull', receipt);
      setStartMessage({
        color: 'success',
        message: t('start.success', {
          url: getChainScannerUrl(tx.hash),
        }),
      });

      const rafflesLength = await contract.getRafflesLength();

      await syncRaffle((rafflesLength - BigInt(1)).toString());

      await refresh();

      toast.success(t('success'), { duration: 3000 });
    } catch (err: any) {
      console.error('failed to start', err);
      if (err.message && err.message.includes('execution reverted: "ET"')) {
        setStartMessage({
          color: 'danger',
          message: t('start.errors.notEnoughEther'),
        });
      } else if (
        err.message &&
        err.message.includes('execution reverted: "TO"')
      ) {
        setStartMessage({
          color: 'danger',
          message: t('start.errors.notEnoughRaffles'),
        });
      } else if (!metamaskErrors.includes(err)) {
        setStartMessage({
          color: 'danger',
          message: t('start.errors.start'),
        });
      }
    }
    setLoading(false);
  }

  return (
    <DashboardBox className="overflow-auto">
      <div className="text-xl font-medium mb-6">{t('start.title')}</div>
      {startMessage && (
        <Notification color={startMessage.color} disableScroll>
          {HTMLReactParser(startMessage.message)}
        </Notification>
      )}

      <RaffleForm onSubmit={onSubmit} loading={loading}></RaffleForm>
    </DashboardBox>
  );
}

export default StartRaffle;
