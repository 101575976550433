import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loadRaffleTokenContract } from '../../../../contracts/raffle-token';
import { getPublicRpc } from '../../../../utils/rpc-rotater';
import Web3Balance from '../../../shared/balance';
import Title from '../../../title';
import styles from './index.module.css';

export default function PrizePool() {
  const { t } = useTranslation('home');
  const [currency, setCurrency] = useState<'ether' | 'raffle'>('ether');
  const [ether, setEther] = useState<bigint>(BigInt(0));
  const [raffle, setRaffle] = useState<bigint>(BigInt(0));

  useEffect(() => {
    loadPrizePool();
  }, []);

  async function loadPrizePool() {
    const provider = getPublicRpc();

    const eth = await provider.getBalance(
      process.env.REACT_APP_RAFFLE_ADDRESS!
    );
    setEther(eth);

    const raf = await loadRaffleTokenContract(provider!).balanceOf(
      process.env.REACT_APP_RAFFLE_ADDRESS!
    );

    setRaffle(raf);
  }

  return (
    <>
      <Title className="mb-[-0.7rem] max-md:leading-none max-md:my-2">
        {t('header.prizePool')}
      </Title>
      <div
        className={styles.background}
        onClick={() => setCurrency(currency === 'ether' ? 'raffle' : 'ether')}
      >
        {currency === 'ether' && (
          <>
            <img
              alt="ether"
              src="/images/ether.svg"
              className="w-[64px] max-md:w-[40px]"
            />
            <span>
              <Web3Balance balance={ether} />
            </span>
          </>
        )}
        {currency === 'raffle' && (
          <>
            <div className="w-[64px] max-md:w-[40px]">
              <img
                alt="raffle"
                src="/images/prize-pool-raffle.svg"
                className="w-[43px] max-md:w-[25px]"
              />
            </div>

            <span>
              <Web3Balance balance={raffle} />
            </span>
          </>
        )}
      </div>
    </>
  );
}
